import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "./../common/form";
import withMyHook from "./../common/withMyHook";
import Navbar from "./../components/navbar";

// Services
import httpService from "./../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

class StocktakeNameForm extends Form {
  state = {
    data: {
      complete: "",
      countingPeriodID: "",
      kioskID: "",
      stockTakeDescription: "",
      stockTakeDate: "",
      stocktakeName: "",
      typeID: "",
    },
    // Loading data variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    errors: {},
    completedData: [
      { id: 0, description: "No" },
      { id: 1, description: "Yes" },
    ],

    kiosks: [],
    periodData: [],
    typeData: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    complete: Joi.string().label("Complete?"),
    countingPeriodID: Joi.string().label("Counting Period"),
    kioskID: Joi.string().label("Kiosk"),
    stockTakeDescription: Joi.string().label("Description"),
    stockTakeDate: Joi.string().label("Stock Take Date"),
    stocktakeName: Joi.string().label("Stock Take Name"),
    typeID: Joi.string().label("Stock Take Type"),
  };

  async componentDidMount() {
    const stockTakeID = this.state.myParams.id;

    // Get a list of Kiosks
    let { data: DbdataKiosks } = await httpService.get(config.apiKiosksGet);

    // Get a list of Stock take types
    let { data: DbdataSSTTypes } = await httpService.get(
      config.apiStockTakeTypesGet
    );

    // Get a list of Stock take periods
    let { data: DbdataSTPeriods } = await httpService.get(
      config.apiStockTakePeriodsGet
    );

    this.setState({
      kiosks: DbdataKiosks,
      typeData: DbdataSSTTypes,
      periodData: DbdataSTPeriods,
    });

    // Get data from database to display options in textfields
    if (stockTakeID === "new") return;
    let { data: stocktakeSingle } = await httpService.get(
      config.apiStockTakeGet + "?id=" + stockTakeID
    );
    if (!stocktakeSingle) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(stocktakeSingle) });
  }

  doSubmit = async () => {
    const ID = this.state.myParams.id;

    if (ID === "new") {
      // add a new entry
      let dataToSend = this.state.data;
      let formData = new FormData();

      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      try {
        await httpService.post(config.apiStockTakesAdd, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while adding.");
      }
    } else {
      // Update an entry

      let dataToSend = this.state.data;
      let formData = new FormData();
      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      try {
        await httpService.post(config.apiStockTakeUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate("/pageStocktake");
  };

  //Submit a delete Group
  doDelete = async () => {
    const ID = this.state.myParams.id;
    try {
      await httpService.get(config.apiStockTakeDelete + "?id=" + ID);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }
    this.state.myNavigate("/pageStocktake");
  };

  doClear = () => {
    let data = {
      stocktakeName: "",
      stockTakeDescription: "",
      stockTakeDate: "",
      complete: "",
      countingPeriodID: "",
      typeID: "",
      countingPeriodID: "",
    };

    this.setState({ data: this.mapToViewModel(data) });
    this.state.myParams.id = "new";
    this.state.myNavigate("/stocktakeNameForm/new");
  };

  mapToViewModel(data) {
    return {
      complete: data.complete,
      kioskID: data.kioskID,
      stocktakeName: data.stocktakeName,
      stockTakeDescription: data.stockTakeDescription,
      stockTakeDate: data.stockTakeDate,
      typeID: data.typeID,
      countingPeriodID: data.countingPeriodID,
    };
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    const { color, cssOverride, loading } = this.state;
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <Navbar />
        <div className="container align-center-all">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container ">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to={"/pageStocktake"}
                          className="btn  btn-sm px-3 button-color-babyblue"
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Back
                        </Link>
                      </th>
                      <th className="text-alight-center ">
                        <h4>Stock Take</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {this.renderInput("stocktakeName", "Stocktake Name")}
                      </td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>
                        {this.renderTextArea(
                          "stockTakeDescription",
                          "Stock take Description",
                          "",
                          "10",
                          "5"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td>
                        {this.renderInput(
                          "stockTakeDate",
                          "Stock Take Date",
                          "date"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Type</td>
                      <td>
                        {this.renderSelect(
                          "typeID",
                          "description",
                          this.state.typeData,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Kiosk</td>
                      <td>
                        {this.renderSelect(
                          "kioskID",
                          "kioskName",
                          this.state.kiosks,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Counting Period</td>
                      <td>
                        {this.renderSelect(
                          "countingPeriodID",
                          "description",
                          this.state.periodData,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Complete ?</td>
                      <td>
                        {this.renderSelect(
                          "complete",
                          "description",
                          this.state.completedData,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(StocktakeNameForm);
