import React from "react";

import Joi from "joi-browser";
import Form from "./../common/form";
import withMyHook from "./../common/withMyHook";
import Navbar from "./../components/navbar";

// Services
import httpService from "./../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom } from "react-toastify";

class crossreferenceForm extends Form {
  state = {
    data: {
      itemCode: "",
      crossrefnumber: "",
    },
    // Loading data variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    errors: {},

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    itemCode: Joi.string().label("Item Code"),
    crossrefnumber: Joi.string().label("Cross Reference Number"),
  };

  async componentDidMount() {
    const itemCodeID = this.state.myParams.id;

    if (itemCodeID === "new") return;
    let { data: crossreferenceSingle } = await httpService.get(
      config.apiCrossReferanceGet + "?id=" + itemCodeID
    );
    if (!crossreferenceSingle) return this.state.myNavigate("/not-found");
    this.setState({ data: this.mapToViewModel(crossreferenceSingle) });
  }

  doSubmit = async () => {
    // const ID = this.state.myParams.id;
    // if (ID === "new") {
    //   // add a new entry
    //   let dataToSend = this.state.data;
    //   let formData = new FormData();
    //   Object.keys(dataToSend).forEach((key) => {
    //     formData.append(key, dataToSend[key]);
    //   });
    //   try {
    //     await httpService.post(config.apiStockTakesAdd, formData);
    //   } catch (error) {
    //     if (error.reponse && error.reponse.status === 404)
    //       toast.error("Something failed while adding.");
    //   }
    // } else {
    //   // Update an entry
    //   console.log("in update");
    //   let dataToSend = this.state.data;
    //   let formData = new FormData();
    //   //add the Id to the data to send to db
    //   formData.append("id", ID);
    //   //loop through the data and add to from data
    //   Object.keys(dataToSend).forEach((key) => {
    //     formData.append(key, dataToSend[key]);
    //   });
    //   try {
    //     await httpService.post(config.apiStockTakeUpdate, formData);
    //   } catch (error) {
    //     if (error.reponse && error.reponse.status === 404)
    //       toast.error("Something failed while updating.");
    //   }
    // }
    // this.state.myNavigate("/pageStocktake");
  };

  //Submit a delete Group
  doDelete = async () => {
    // const ID = this.state.myParams.id;
    // try {
    //   const deleteReply = await httpService.get(
    //     config.apiStockTakeDelete + "?id=" + ID
    //   );
    //   var myObject = JSON.parse(deleteReply);
    // } catch (error) {
    //   if (error.reponse && error.reponse.status === 404)
    //     toast.error("Something failed while deleting.");
    // }
    // this.state.myNavigate("/pageStocktake");
  };

  doClear = () => {
    // let data = {
    //   stocktakeName: "",
    //   stockTakeDescription: "",
    //   stockTakeDate: "",
    //   complete: "",
    // };
    // this.setState({ data: this.mapToViewModel(data) });
    // this.state.myParams.id = "new";
    // this.state.myNavigate("/stocktakeNameForm/new");
  };

  mapToViewModel(data) {
    return {
      itemCode: data.itemCode,
      crossrefnumber: data.crossrefno,
    };
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <Navbar />
        <div className="row ">
          <div className="form-container">
            <form onSubmit={this.handleSubmit} id="formID">
              <table className="table table-group">
                <thead>
                  <tr align="center">
                    <th colSpan={2}>
                      <h4>Cross Reference</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Item Code</td>
                    <td>{this.renderInput("itemCode", "Item Code")}</td>
                  </tr>
                  <tr>
                    <td>Cross Reference Number</td>
                    <td>
                      {this.renderInput(
                        "crossrefnumber",
                        "Cross Reference Number"
                      )}
                    </td>
                  </tr>

                  <tr align="center">
                    <td colSpan={2}>
                      <span className="button-padding">
                        {this.renderSaveButton("Save")}
                      </span>
                      <span className="button-padding">
                        {this.renderButtonDelete("Delete")}
                      </span>
                      <span className="button-padding">
                        {this.renderButtonClear("Clear")}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(crossreferenceForm);
