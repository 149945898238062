import React, { Component } from "react";

//Components
import NavbarEH from "./components/navbarEH";
import KioskCard from "./components/kioskCard";
import withMyHook from "./common/withMyHook";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

class PageKioskSelect extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    fullname: "",

    // Arrays
    kioskArray: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const staffID = this.state.myParams.id;
    this.state.fullname = localStorage.getItem("fullname");
    localStorage.setItem("kioskID", "");

    // Get a list of the kiosks that are allocated to the staff member
    let { data: KioskData } = await httpService.get(
      config.apiLoginAllocations + "?id=" + staffID
    );
    this.setState({ kioskArray: KioskData });
  }

  render() {
    const { color, cssOverride, fullname, kioskArray, loading } = this.state;

    return (
      <React.Fragment>
        <NavbarEH />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row align-start-horizontal">
            <div data-aos="fade-right ">
              <span className="display-name">{fullname}</span>
            </div>
          </div>
          <div className="row kioskList">
            <div className="px-2  text-center">
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                  Select the kiosk/s below you have been allocated, to to
                  perform stock take functions.
                </p>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row kioskList ">
            {kioskArray.map((kioskData) => (
              <KioskCard key={kioskData.id} kioskData={kioskData} />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageKioskSelect);
