import React from "react";
import Form from "./form";

// Componenets
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { SiMicrosoftexcel } from "react-icons/si";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class Excelexport extends Form {
  state = {
    fileType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    fileExtension: ".xlsx",
  };

  exportToExcel = async ({ excelData, fileName, tabname }) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelbuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelbuffer], { type: this.state.fileType });
    FileSaver.saveAs(data, fileName + this.state.fileExtension);
  };

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const { excelData, fileName, tabname } = this.props;

    return (
      <div>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={this.renderTooltip("Export to excel")}
        >
          <button
            type="button"
            className="btn border-none"
            onClick={(e) => {
              this.exportToExcel({ excelData, fileName, tabname });
            }}
          >
            <span>
              <SiMicrosoftexcel color="#0e763c" size={30} />
            </span>
          </button>
        </OverlayTrigger>
      </div>
    );
  }
}

export default Excelexport;
