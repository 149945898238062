import React, { Component } from "react";

import { Link } from "react-router-dom";
// React Spinner
import PuffLoader from "react-spinners/PuffLoader";
// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// Services
import httpService from "./../services/httpService.js";
import config from "./../../config.json";

class DeleteStockTakeItems extends Component {
  state = {
    disabledVar: "",
    counterID: "",
    holderID: "",
    kioskID: "",
    passedkioskID: "",
    passedStockTakeID: "",
    stockTakeID: "",
    kioskID: "",
    kioskName: "",

    // Loading Variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    // Arrays
    STdataRetreived: [],
    KIOSKdataRetreived: [],
    KIOSKCOUNTERdataRetreived: [],
    KIOSKHOLDERdataRetreived: [],
  };

  async componentDidMount() {
    const kioskID = localStorage.getItem("kioskID");
    const kioskName = localStorage.getItem("kioskName");
    this.setState({
      kioskID,
      kioskName,
    });
  }

  handleSelect = async () => {
    // Get the Value of the select the user has chosen
    var e = document.getElementById("StockTakeSelect");

    //Get the value of the selected option
    let StockTakeSelectID = e.value;

    // Get the text of the selected option
    var selectText = e.options[e.selectedIndex].text;
    this.setState({
      stockTakeID: StockTakeSelectID,
    });

    localStorage.setItem("StocktakeID", StockTakeSelectID);

    if (selectText == "Select Stock Take...") {
      localStorage.setItem("kioskID", "");
      localStorage.setItem("CounterID", "");
      localStorage.setItem("HolderID", "");

      // reset the counter and holder and Variables

      this.setState({
        KIOSKCOUNTERdataRetreived: [],
        KIOSKHOLDERdataRetreived: [],
        kioskID: "",
        counterID: "",
        holderID: "",
        disabledVar: true,
      });
    } else {
      this.setState({
        disabledVar: false,
      });
      // Get the Kiosk id and Load the counters
      let { data: stocktakeSingle } = await httpService.get(
        config.apiStockTakeGet + "?id=" + StockTakeSelectID
      );
      this.handleStockTakeSelection(stocktakeSingle.kioskID, StockTakeSelectID);
    }
  };

  // This is called when the stock take select box is changed
  handleStockTakeSelection = async (KioskID, StockTakeSelectID) => {
    localStorage.setItem("kioskID", "");
    localStorage.setItem("CounterID", "");
    localStorage.setItem("HolderID", "");

    // Reset the variables
    this.setState({
      KIOSKCOUNTERdataRetreived: [],
      KIOSKHOLDERdataRetreived: [],
      kioskID: "",
      counterID: "",
      holderID: "",
      disabledVar: true,
    });

    this.setState({
      kioskID: KioskID,
    });

    localStorage.setItem("kioskID", KioskID);
    localStorage.setItem("StocktakeID", StockTakeSelectID);

    let { data: Counters } = await httpService.get(
      config.apiCountersGet + "?id=" + KioskID
    );
    if (Counters) {
      this.setState({
        KIOSKCOUNTERdataRetreived: Counters,
      });
    } else {
      this.setState({
        KIOSKCOUNTERdataRetreived: [],
      });
    }
  };

  // This is called when the display set select box is changed
  handleCounterSelection = async () => {
    // Reset the variables
    this.setState({
      KIOSKHOLDERdataRetreived: [],
      counterID: "",
      holderID: "",
    });

    localStorage.setItem("CounterID", "");
    localStorage.setItem("HolderID", "");

    // Get the selected kiosk
    var e = document.getElementById("CounterSelect");

    // Get the value of the selected option
    let CounterSelectID = e.value;
    this.setState({ counterID: CounterSelectID });

    localStorage.setItem("CounterID", CounterSelectID);

    let { data: Holders } = await httpService.get(
      config.apiCounterHoldersGet + "?id=" + CounterSelectID
    );
    if (Holders) {
      this.setState({ KIOSKHOLDERdataRetreived: Holders });
    } else {
      this.setState({ KIOSKHOLDERdataRetreived: [] });
    }

    let StockTakeSelectID = localStorage.getItem("StocktakeID");
  };

  // This is called when the holder select box is changed
  handleHolderSelection = async () => {
    console.log(" In holder Selection");
    // reset the variables
    this.setState({
      holderID: "",
    });
    localStorage.setItem("HolderID", "");
    // Get the selected kiosk
    var e = document.getElementById("HolderSelect");

    //Get the value of the selected option
    let HolderSelectID = e.value;
    console.log(" HolderSelectID =" + HolderSelectID);
    this.setState({ holderID: HolderSelectID });

    localStorage.setItem("HolderID", HolderSelectID);

    // holderID
    let StockTakeSelectID = localStorage.getItem("StocktakeID");
    let CounterSelectID = localStorage.getItem("CounterID");
  };

  // code to delete the scans from the database
  handleClearScans = async () => {
    let StockTakeSelectID = localStorage.getItem("StocktakeID");

    this.on();

    if (StockTakeSelectID) {
      console.log(
        " In Stock take selected delete. StockTakeSelectID = " +
          StockTakeSelectID
      );
      //   try {
      //     const deleteReply = await httpService.get(
      //       config.apiItemScansStockTakeDisplaySetHolderDelete +
      //         "?StockTakeSelectID=" +
      //         StockTakeSelectID
      //     );
      //     var myObject = JSON.parse(deleteReply);
      //     toast.success("Scans Deleted");
      //   } catch (error) {
      //     if (error.reponse && error.reponse.status === 404)
      //       toast.error("Something failed while deleting.");
      //   }
    }
    this.off();
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    const { STdataRetreived } = this.props;

    return (
      <div className="container">
        <div id="overlay" className="overlay">
          <div id="text" className="text">
            <PuffLoader
              color={this.state.color}
              loading={this.state.loading}
              cssOverride={this.state.cssOverride}
              size={50}
            />
            Uploading Data...
          </div>
        </div>

        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={200}
          position="top-center"
        />

        <div className="row  m-2 ">
          <div className="clearscans-style p-4 ">
            <form>
              <div className="row p-2">
                Select the Stock take below to delete "All" the imported stock
                items from the chosen stock take by pressing the delete button
                below.
                <br />
              </div>
              <div className="row">
                <select
                  className="form-select textfeild-size-300 button-margin-all"
                  onChange={this.handleSelect}
                  id="StockTakeSelect"
                  // disabled={disabledVar3}
                >
                  <option defaultValue>Select Stock Take...</option>
                  {STdataRetreived &&
                    STdataRetreived.map((STdataRetreivedSingle) => (
                      <option
                        key={STdataRetreivedSingle.id}
                        value={STdataRetreivedSingle.id}
                      >
                        {STdataRetreivedSingle["stocktakeName"]}
                      </option>
                    ))}
                </select>
              </div>

              <div className="row">
                <button
                  type="button"
                  className="btn btn-danger button-margin-all button-size-100"
                  onClick={this.handleClearScans}
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteStockTakeItems;
