import React, { Component } from "react";
//Components
import NavbarEH from "./components/navbarEH";
import ImportCSV from "./components/importCSV";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

class PageImportCSV extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,
    STdataRetreived: [],
  };

  async componentDidMount() {
    let kioskID = localStorage.getItem("kioskID");

    // Get all the stock take data
    let { data: Dbdata } = await httpService.get(config.apiStockTakesGet);
    console.log(Dbdata);

    this.setState({
      STdataRetreived: Dbdata,
    });
  }
  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  doSubmit = async (ImportArray, csvType, stockTakeID) => {
    this.on();
    let totalRecords = "";
    let sendArray = [];
    let tableName = "";
    let counter = 0;

    //Now send the data to the database
    ImportArray.map((item) => {
      let singleRow = [];

      Object.values(item).map((val) =>
        // append the data to the single array
        singleRow.push(val)
      );
      sendArray.push(singleRow);
    });

    let formData = new FormData();
    formData.append("csvArray[]", sendArray);
    formData.append("csvType", csvType);
    formData.append("stockTakeID", stockTakeID);

    try {
      httpService.post(config.apiUploadCSV, formData);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed");
    }

    this.off();
    toast.success("Data uploaded");
  };

  render() {
    const { color, cssOverride, loading, STdataRetreived } = this.state;

    return (
      <React.Fragment>
        <NavbarEH />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <ToastContainer
            draggable={true}
            transition={Zoom}
            autoClose={1000}
            position="top-center"
          />
          <ImportCSV
            onCSVSubmit={this.doSubmit}
            on={this.on}
            off={this.off}
            STdataRetreived={STdataRetreived}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PageImportCSV;
