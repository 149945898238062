import React, { useState } from "react";
import { Link } from "react-router-dom";

// Services
import httpService from "./../services/httpService.js";
import config from "./../../config.json";

// Utilities
import { ToastContainer, Zoom } from "react-toastify";
import _ from "lodash";
// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

// Common
import Table from "./../common/table";
import Excelexport from "./../common/excelexport";

// Componenets
import SearchBox from "../common/searchBox.jsx";

function StockTakeResults({ on, off, STdataRetreived, doRefresh }) {
  // Variables
  // const [result, setResult] = useState("No result");
  // const [kioskID, setkioskID] = useState(null);
  // const [counterID, setcounterID] = useState(null);
  // const [holderID, setholderID] = useState(null);
  // const [totalsArray, settotalsArray] = useState([]);
  // const [KIOSKCOUNTERdataRetreived, setKIOSKCOUNTERdataRetreived] = useState(
  //   []
  // );
  // const [KIOSKHOLDERdataRetreived, setKIOSKHOLDERdataRetreived] = useState([]);
  const [disabledVar, setDisabled] = useState(true);
  const [stockTakeID, setStockTakeID] = useState(null);
  const [goToPage, setgoToPage] = useState("pageItemScan");
  const [sortColumn, setsortColumn] = useState({
    path: "itemCode",
    order: "asc",
  });
  const [stockItemsScanned, setStockItemScanned] = useState([]);
  const [varianceData, setVarianceData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryDesc, setSearchQueryDesc] = useState("");
  //kiosk figures
  let [TotalVariance, setTotalVariance] = useState(0);
  let [Totalcost, setTotalcost] = useState(0);
  let [Qty, setQty] = useState(0);
  let [ScannedQty, setScannedQty] = useState(0);
  let [VarianceQty, setVarianceQty] = useState(0);
  let [VariancePer, setVariancePer] = useState(0);
  // overs
  let [OverQty, setOverQty] = useState(0);
  let [OverValue, setOverValue] = useState(0);
  let [OverPer, setOverPer] = useState(0);
  // Unders
  let [underQty, setUnderQty] = useState(0);
  let [underValue, setUnderValue] = useState(0);
  let [underPer, setUnderPer] = useState(0);

  let [scanValue, setScanValue] = useState(0);

  const color = "#002072";
  const cssOverride = {
    display: "block",
    margin: "0 auto",
    borderColor: "#002072",
  };
  const speedMultiplier = 1;
  const loading = true;

  const columns = [
    {
      path: "itemCode",
      label: "Item Code",
      content: (scannedItem) => (
        <Link to="">
          <h6>{scannedItem.itemCode}</h6>
        </Link>
      ),
    },
    { path: "description", label: "Description" },
    { path: "qty", label: "Qty" },
    { path: "scanned", label: "Scanned" },
    { path: "variance", label: "Variance" },
    { path: "unitCost", label: "Unit Cost" },
    { path: "totalCost", label: "Total Cost" },
    { path: "varianceCost", label: "Variance Cost" },
  ];

  const columnsOvers = [
    {
      path: "itemCode",
      label: "Item Code",
      content: (scannedItem) => (
        <Link to="">
          <h6>{scannedItem.itemCode}</h6>
        </Link>
      ),
    },
    { path: "description", label: "Description" },
    { path: "qty", label: "Qty" },
    { path: "scanned", label: "Scanned" },
    { path: "variance", label: "Variance" },
    { path: "unitCost", label: "Unit Cost" },
    { path: "totalCost", label: "Total Cost" },
    { path: "varianceCost", label: "Variance Cost" },
  ];
  // Set the back button address for each user type

  //Functions

  const handleGetStockItems = async (StockTakeSelectID) => {
    if (StockTakeSelectID) {
      let { data: DbdataScanns } = await httpService.get(
        config.apiStockTakeItemsGet + "?id=" + StockTakeSelectID
      );
      const sortedData = _.orderBy(DbdataScanns, "itemCode", "ASC");
      // Calculate the variance Totals
      let TotalVariance = 0;
      let Totalcost = 0;
      let Qty = 0;
      let ScannedQty = 0;
      let VarianceQty = 0;
      let OverQty = 0;
      let OverValue = 0;
      let OverPer = 0;
      let underQty = 0;
      let underValue = 0;
      let underPer = 0;
      sortedData.map(
        (stockTakeRow) => (
          (TotalVariance += parseFloat(stockTakeRow.varianceCost)),
          (Totalcost += parseFloat(stockTakeRow.totalCost)),
          (Qty += parseInt(stockTakeRow.qty)),
          (ScannedQty += parseInt(stockTakeRow.scanned)),
          (VarianceQty += parseInt(stockTakeRow.variance)),
          (OverQty += stockTakeRow.overQty),
          (OverValue += stockTakeRow.overValue),
          (underQty += stockTakeRow.underQty),
          (underValue += stockTakeRow.underValue),
          (scanValue += stockTakeRow.scanValue)
        )
      );
      if (TotalVariance > 0 && Totalcost > 0) {
        VariancePer = (TotalVariance / Totalcost) * 100;
        VariancePer = parseInt(VariancePer);
      }

      // overs
      if (OverValue > 0 && Totalcost > 0) {
        OverPer = (OverValue / Totalcost) * 100;
        OverPer = parseInt(OverPer);
      }
      if (OverValue > 0) {
        OverValue = Math.round((OverValue + Number.EPSILON) * 100) / 100;
        OverValue = parseInt(OverValue);
        OverValue = OverValue.toLocaleString("en-US");
      }
      // Unders
      if (underValue > 0 && Totalcost > 0) {
        underPer = (underValue / Totalcost) * 100;
        underPer = parseInt(underPer);
      }
      underValue = Math.round((underValue + Number.EPSILON) * 100) / 100;
      underValue = parseInt(underValue);
      underValue = underValue.toLocaleString("en-US");
      scanValue = Math.round((scanValue + Number.EPSILON) * 100) / 100;
      scanValue = parseInt(scanValue);
      scanValue = scanValue.toLocaleString("en-US");
      Totalcost = Math.round((Totalcost + Number.EPSILON) * 100) / 100;
      Totalcost = parseInt(Totalcost);
      Totalcost = Totalcost.toLocaleString("en-US");
      TotalVariance = Math.round((TotalVariance + Number.EPSILON) * 100) / 100;
      TotalVariance = parseInt(TotalVariance);
      TotalVariance = TotalVariance.toLocaleString("en-US");
      setTotalVariance(TotalVariance);
      setTotalcost(Totalcost);
      setQty(Qty);
      setScannedQty(ScannedQty);
      setVarianceQty(VarianceQty);
      setVariancePer(VariancePer);
      // Overs
      setOverQty(OverQty);
      setOverValue(OverValue);
      setOverPer(OverPer);
      // Unders
      setUnderQty(underQty);
      setUnderValue(underValue);
      setUnderPer(underPer);
      setScanValue(scanValue);
      if (sortedData.length != 0) {
        setStockItemScanned(sortedData);
      } else {
        setStockItemScanned([]);
      }
    } //close if kiosk id
  };

  const handleGetVariances = async (StockTakeSelectID) => {
    if (StockTakeSelectID) {
      let { data: DbdataVariances } = await httpService.get(
        config.apiItemScannedVaraincesGet + "?id=" + StockTakeSelectID
      );
      const sortedData = _.orderBy(DbdataVariances, "itemCode", "ASC");
      if (sortedData.length != 0) {
        setVarianceData(sortedData);
      } else {
        setVarianceData([]);
      }
    } //close if kiosk id
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleSelect = async () => {
    // Get the Value of the select the user has chosen
    var e = document.getElementById("StockTakeSelect");

    //Get the value of the selected option
    let StockTakeSelectID = e.value;

    // Get the text of the selected option
    var selectText = e.options[e.selectedIndex].text;
    setStockTakeID(StockTakeSelectID);
    localStorage.setItem("StocktakeID", StockTakeSelectID);

    if (selectText == "Select Stock Take...") {
      setDisabled(true);
      setgoToPage("pageSTResults");
    } else {
      setDisabled(false);
      setgoToPage("PageItemSearchTable");

      handleGetStockItems(StockTakeSelectID);
      handleGetVariances(StockTakeSelectID);
    }
  };

  const handleSort = (sortColumn) => {
    setsortColumn(sortColumn);
  };
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleSearchDesc = (query) => {
    setSearchQueryDesc(query);
  };

  const handleRefresh = () => {
    var e = document.getElementById("StockTakeSelect");
    //Get the value of the selected option
    let StockTakeSelectID = e.value;
    handleGetStockItems(StockTakeSelectID);
    handleGetVariances(StockTakeSelectID);
  };

  // filter the data from search input contents
  let filtered = stockItemsScanned;
  let filteredVAR = varianceData;
  if (searchQuery) {
    filtered = stockItemsScanned.filter((m) =>
      m.itemCode.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
    filteredVAR = varianceData.filter((m) =>
      m.itemCode.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  }

  if (searchQueryDesc) {
    filtered = stockItemsScanned.filter((m) =>
      m.description.toLowerCase().includes(searchQueryDesc.toLowerCase())
    );
    filteredVAR = varianceData.filter((m) =>
      m.description.toLowerCase().includes(searchQueryDesc.toLowerCase())
    );
  }
  return (
    <div className="container">
      <div id="overlay" className="overlay">
        <div id="text" className="text">
          <PuffLoader
            color={color}
            loading={loading}
            cssOverride={cssOverride}
            size={50}
          />
          Uploading Data...
        </div>
      </div>
      <ToastContainer
        draggable={true}
        transition={Zoom}
        autoClose={200}
        position="top-center"
      />
      <div className="row">
        <div>
          <form>
            <div className="align-buttons">
              <select
                className="form-select button-size-400 button-margin-all"
                aria-label="Default select example"
                id="StockTakeSelect"
                onChange={handleSelect}
              >
                <option defaultValue>Select Stock Take...</option>
                {STdataRetreived.map((STdataRetreivedSingle) => (
                  <option
                    key={STdataRetreivedSingle.id}
                    value={STdataRetreivedSingle.id}
                  >
                    {`${STdataRetreivedSingle["stocktakeName"]} (${STdataRetreivedSingle["stockTakeDate"]})`}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="btn button-color-babyblue button-margin-all"
                onClick={(e) => {
                  handleRefresh();
                }}
              >
                Refresh
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="align-buttons align-center-all ">
            <SearchBox
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search Item code ..."
            />
            <SearchBox
              value={searchQueryDesc}
              onChange={handleSearchDesc}
              placeholder="Search Description ..."
            />
          </div>
        </div>

        <div>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-summary-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-sum"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Summary
              </button>
              <button
                className="nav-link "
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                All Items
              </button>
              <button
                className="nav-link"
                id="nav-var-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-var"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Variances
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-sum"
              role="tabpanel"
              aria-labelledby="nav-summary-tab"
            >
              <div className="anyClass overflow-auto padding-all-4  padding-top-20 align-center-self">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="card bg-c-grey order-card">
                        <div className="card-block">
                          <h5 className="m-b-20">Stock Holding</h5>
                          <h2 className="text-right color-override-white">
                            <i className="fa fa-cart-plus f-left "></i>
                            <span>{Qty}</span>
                          </h2>
                          <p className="m-b-0">
                            <br />
                            Cost
                            <span className="f-right">R {Totalcost}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                      <div className="card bg-c-blue order-card">
                        <div className="card-block">
                          <h5 className="m-b-20">Scans</h5>
                          <h2 className="text-right color-override-white">
                            <i className="fa fa-cart-plus f-left "></i>
                            <span>{ScannedQty}</span>
                          </h2>
                          <p className="m-b-0">
                            <br />
                            Cost
                            <span className="f-right">R {scanValue}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="card bg-c-green order-card">
                        <div className="card-block">
                          <h5 className="m-b-20">Overs</h5>
                          <h2 className="text-right color-override-white">
                            <i className="fa fa-rocket f-left"></i>
                            <span>{OverQty}</span>
                          </h2>
                          <p className="m-b-0">
                            % of Holding
                            <span className="f-right">{OverPer}</span> <br />
                            Cost<span className="f-right">R {OverValue}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                      <div className="card bg-c-yellow order-card">
                        <div className="card-block">
                          <h5 className="m-b-20">Short</h5>
                          <h2 className="text-right color-override-white">
                            <i className="fa fa-refresh f-left"></i>
                            <span>{underQty}</span>
                          </h2>
                          <p className="m-b-0">
                            % of Holding
                            <span className="f-right">{underPer}</span>
                            <br />
                            Cost<span className="f-right">R {underValue}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-xl-3">
                      <div className="card bg-c-pink order-card">
                        <div className="card-block">
                          <h5 className="m-b-20">Variance</h5>
                          <h2 className="text-right color-override-white">
                            <i className="fa fa-credit-card f-left"></i>
                            <span>{VarianceQty}</span>
                          </h2>
                          <p className="m-b-0">
                            % of Holding
                            <span className="f-right">{VariancePer}</span>
                            <br />
                            Cost
                            <span className="f-right">R {TotalVariance}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade "
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="anyClass overflow-auto padding-all-4 ">
                <Table
                  columns={columns}
                  sortColumn={sortColumn}
                  onSort={handleSort}
                  data={filtered}
                />
              </div>
              <div className=" row button-margin-all">
                <div className="col ">Export to Excel</div>

                <div className="col align-end-all m-0 p-0">
                  {filtered && (
                    <Excelexport
                      excelData={filtered}
                      fileName={"All Items"}
                      tabname={"All Items"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-var"
              role="tabpanel"
              aria-labelledby="nav-variances-tab"
            >
              <div className="anyClass overflow-auto padding-all-4 ">
                <Table
                  columns={columnsOvers}
                  sortColumn={sortColumn}
                  onSort={handleSort}
                  data={filteredVAR}
                />
              </div>
              <div className=" row button-margin-all">
                <div className="col ">Export to Excel</div>

                <div className="col align-end-all m-0 p-0">
                  {filteredVAR && (
                    <Excelexport
                      excelData={filteredVAR}
                      fileName={"Variances"}
                      tabname={"Variances"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockTakeResults;
