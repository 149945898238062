import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "./../common/table";
import Pagination from "./../common/pagination";

//Utilites
import { paginate } from "../utils/paginate";

class StockTakeTable extends React.Component {
  columns = [
    {
      path: "stocktakeName",
      label: "Name",
      content: (stocktake) => (
        <Link to={`/stocktakeNameForm/${stocktake.id}`}>
          <h6>{stocktake.stocktakeName}</h6>
        </Link>
      ),
    },
    { path: "Kioskname", label: "Kiosk" },
    { path: "stockTakeDescription", label: "Description" },
    { path: "stockTakeDate", label: "Date" },
    { path: "typeDescription", label: "Type" },
    { path: "countingPeriodDesc", label: "Period" },
    { path: "complete", label: "Completed" },
  ];

  render() {
    const {
      allEntriesTotal,
      currentPage,
      filtereddata,
      onDelete,
      onPageChange,
      onPageDecrement,
      onPageIncrement,
      onSort,
      pageSize,
      sortColumn,
    } = this.props;

    const dataPag = paginate(filtereddata, currentPage, pageSize);

    return (
      <React.Fragment>
        <div>
          <Pagination
            itemsCount={allEntriesTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageDecrement={onPageDecrement}
            onPageIncrement={onPageIncrement}
          />
        </div>
        <div className="table-responsive">
          <Table
            columns={this.columns}
            sortColumn={sortColumn}
            onSort={onSort}
            data={dataPag}
            onDelete={onDelete}
          />
        </div>
        <div>
          <Pagination
            itemsCount={allEntriesTotal}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onPageDecrement={onPageDecrement}
            onPageIncrement={onPageIncrement}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default StockTakeTable;
