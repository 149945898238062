import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";

//Components
import NavbarEH from "./components/navbarEH";
import SearchBox from "./common/searchBox";
import withMyHook from "./common/withMyHook";
import StockTakeTable from "./components/stockTakeTable";
import Form from "./common/form";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

import { ArrowLeft } from "react-bootstrap-icons";

class PageStockTake extends Form {
  state = {
    data: {
      typeID: "",
      countingPeriodID: "",
    },
    countingPeriodID: "",
    currentPage: "1",
    goToPage: "",
    kioskID: "",
    KioskSelectedID: "",
    KioskSelectedDesc: "",
    kioskName: "",
    pageSize: 10,
    stockTakeID: "",
    STTID: "",
    STPeriod: "",
    typeID: "",
    userID: "",

    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    searchQuery: "",
    sortColumn: { path: "stockTakeName", order: "asc" },

    dataRetreived: [],
    kiosks: [],
    periodData: [],
    typeData: [],
    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  async componentDidMount() {
    const userID = localStorage.getItem("userID");

    this.on();
    // Get a list of Kiosks
    let { data: DbdataKiosks } = await httpService.get(config.apiKiosksGet);

    // Get a list of Stock take types
    let { data: DbdataSSTTypes } = await httpService.get(
      config.apiStockTakeTypesGet
    );

    // Get a list of Stock take periods
    let { data: DbdataSTPeriods } = await httpService.get(
      config.apiStockTakePeriodsGet
    );

    this.setState({
      kiosks: DbdataKiosks,
      typeData: DbdataSSTTypes,
      periodData: DbdataSTPeriods,
    });

    // Get all the stock take data
    let { data: Dbdata } = await httpService.get(config.apiStockTakesTableGet);

    console.log(Dbdata);

    this.setState({
      dataRetreived: Dbdata,
      userID,
    });
    this.off();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage != 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleKioskSelect = () => {
    // Get the Value of the select the user has chosen
    var e = document.getElementById("KioskSelection");
    //Get the value of the selected option
    let KioskSelectedID = e.value;

    // Get the text of the selected option
    let KioskSelectedDesc = e.options[e.selectedIndex].text;

    this.setState({ KioskSelectedID, KioskSelectedDesc });
  };

  handleSTTSelect = () => {
    // Get the Value of the select the user has chosen
    var e = document.getElementById("StockTakeType");
    //Get the value of the selected option
    let STTID = e.value;

    this.setState({ STTID });
  };

  handleSTPeriod = () => {
    // Get the Value of the select the user has chosen
    var e = document.getElementById("StockPeriod");
    //Get the value of the selected option
    let STPeriod = e.value;

    this.setState({ STPeriod });
  };

  render() {
    let {
      color,
      cssOverride,
      currentPage,
      dataRetreived,
      KioskSelectedID,
      KioskSelectedDesc,
      loading,
      pageSize,
      searchQuery,
      sortColumn,
      STPeriod,
      STTID,
      userID,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;

    // tried these
    // omitBy
    // pickBy

    // One selection ==========================

    // Filter on Kiosk ID
    if (KioskSelectedDesc) {
      filtered = sortedData.filter((m) =>
        m.Kioskname.toLowerCase().startsWith(KioskSelectedDesc.toLowerCase())
      );
    }
    // Filter on Type ID
    if (STTID) {
      filtered = sortedData.filter((m) =>
        m.typeID.toLowerCase().startsWith(STTID.toLowerCase())
      );
    }

    // Filter on Stock take period
    if (STPeriod) {
      filtered = sortedData.filter((m) =>
        m.countingPeriodID.toLowerCase().startsWith(STPeriod.toLowerCase())
      );
    }

    // Filter on Stock take name
    if (searchQuery) {
      filtered = sortedData.filter((m) =>
        m.stocktakeName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    // Two selections ===============================

    // Filter on Kiosk ID and Type ID
    if (KioskSelectedDesc && STTID) {
      let filteredKiosk = sortedData.filter((m) =>
        m.Kioskname.toLowerCase().startsWith(KioskSelectedDesc.toLowerCase())
      );
      filtered = filteredKiosk.filter((m) =>
        m.typeID.toLowerCase().startsWith(STTID.toLowerCase())
      );
    }

    // Filter on Kiosk ID and Stock take name
    if (KioskSelectedDesc && searchQuery) {
      let filteredKiosk = sortedData.filter((m) =>
        m.Kioskname.toLowerCase().startsWith(KioskSelectedDesc.toLowerCase())
      );
      filtered = filteredKiosk.filter((m) =>
        m.stocktakeName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    // Filter on Kiosk ID and Stock take period
    if (KioskSelectedDesc && STPeriod) {
      let filteredKiosk = sortedData.filter((m) =>
        m.Kioskname.toLowerCase().startsWith(KioskSelectedDesc.toLowerCase())
      );
      filtered = filteredKiosk.filter((m) =>
        m.countingPeriodID.toLowerCase().startsWith(STPeriod.toLowerCase())
      );
    }

    // Three selections ===============================

    // Filter on Kiosk ID and Type ID
    if (KioskSelectedDesc && STTID && STPeriod) {
      let filteredKiosk = sortedData.filter((m) =>
        m.Kioskname.toLowerCase().startsWith(KioskSelectedDesc.toLowerCase())
      );
      let filteredType = filteredKiosk.filter((m) =>
        m.typeID.toLowerCase().startsWith(STTID.toLowerCase())
      );
      filtered = filteredType.filter((m) =>
        m.countingPeriodID.toLowerCase().startsWith(STPeriod.toLowerCase())
      );
    }

    // Four selections ===============================

    // Filter on Kiosk ID and Type ID
    if (KioskSelectedDesc && STTID && STPeriod && searchQuery) {
      let filteredKiosk = sortedData.filter((m) =>
        m.Kioskname.toLowerCase().startsWith(KioskSelectedDesc.toLowerCase())
      );
      let filteredType = filteredKiosk.filter((m) =>
        m.typeID.toLowerCase().startsWith(STTID.toLowerCase())
      );
      let filteredPeriod = filteredType.filter((m) =>
        m.countingPeriodID.toLowerCase().startsWith(STPeriod.toLowerCase())
      );
      filtered = filteredPeriod.filter((m) =>
        m.stocktakeName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    const allEntriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarEH />

        <div className="container">
          <form>
            <div id="overlay" className="overlay">
              <div id="text" className="text">
                <PuffLoader
                  color={color}
                  loading={loading}
                  cssOverride={cssOverride}
                  size={50}
                />
                Uploading Data...
              </div>
            </div>
            <div className="row">
              <div data-aos="fade-right align-start-horizontal p-5">
                <span className="button-padding">
                  <Link to={`/PageKioskSelect/${userID}`} className="btn p-0">
                    <ArrowLeft color="#bae8e6" size={30} />
                  </Link>
                </span>
                <span className="display-name">Stock Takes</span>
              </div>

              <div className="col" data-aos="fade-right">
                <Link
                  to="/stocktakeNameForm/new"
                  className="btn button-color-babyblue button-margin-all"
                >
                  New Stock take
                </Link>
              </div>

              <div className=" col searchBar-width-300" data-aos="fade-left">
                <select
                  className="form-select button-size-250 button-margin-all"
                  aria-label="Default select example"
                  id="KioskSelection"
                  onChange={this.handleKioskSelect}
                >
                  <option defaultValue>Select Kiosk...</option>
                  {this.state.kiosks.map((kiosksSingle) => (
                    <option key={kiosksSingle.id} value={kiosksSingle.id}>
                      {`${kiosksSingle["kioskName"]}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className=" col searchBar-width-300" data-aos="fade-left">
                <select
                  className="form-select button-size-200 button-margin-all"
                  aria-label="Default select example"
                  id="StockTakeType"
                  onChange={this.handleSTTSelect}
                >
                  <option defaultValue>Select Type...</option>
                  {this.state.typeData.map((typeDataSingle) => (
                    <option key={typeDataSingle.id} value={typeDataSingle.id}>
                      {`${typeDataSingle["description"]}`}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" col searchBar-width-300" data-aos="fade-left">
                <select
                  className="form-select button-size-200 button-margin-all"
                  aria-label="Default select example"
                  id="StockPeriod"
                  onChange={this.handleSTPeriod}
                >
                  <option defaultValue>Select Period...</option>
                  {this.state.periodData.map((periodDataSingle) => (
                    <option
                      key={periodDataSingle.id}
                      value={periodDataSingle.id}
                    >
                      {`${periodDataSingle["description"]}`}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" col searchBar-width-300" data-aos="fade-left">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search Stock takes..."
                />
              </div>
            </div>

            <div className="row">
              <div
                className="col table-top-padding"
                data-aos="zoom-in"
                data-aos-duration="5000"
              >
                {filtered == "No Data Retreived" ? (
                  "No Data"
                ) : (
                  <StockTakeTable
                    currentPage={currentPage}
                    filtereddata={filtered}
                    onPageDecrement={this.handlePageDecrement}
                    onPageIncrement={this.handlePageIncrement}
                    onPageChange={this.handlePageChange}
                    onSort={this.handleSort}
                    pageSize={pageSize}
                    sortColumn={sortColumn}
                    allEntriesTotal={allEntriesTotal}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageStockTake);
