import React from "react";
import { Link } from "react-router-dom";

//Components
import NavbarEH from "./components/navbarEH";
import SearchBox from "./common/searchBox";

import CrossReferenceTable from "./components/CrossReferenceTable";

//Other
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

import { ArrowLeft } from "react-bootstrap-icons";

class PageCrossReference extends React.Component {
  state = {
    currentPage: "1",
    dataRetreived: [],
    stockTakeID: "",
    goToPage: "",
    pageSize: 200,
    userID: "",
    fullname: "",

    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    searchQuery: "",
    sortColumn: { path: "itemCode", order: "asc" },

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  async componentDidMount() {
    this.on();

    const userID = localStorage.getItem("userID");
    const fullname = localStorage.getItem("fullname");

    // Get all the stock take data
    let { data: Dbdata } = await httpService.get(config.apiCrossReferancessGet);

    this.setState({
      dataRetreived: Dbdata,
      userID,
      fullname,
    });
    this.off();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handlePageIncrement = (sentCurrentPage, pagesCount) => {
    if (sentCurrentPage < pagesCount) {
      sentCurrentPage++;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageDecrement = (sentCurrentPage) => {
    if (sentCurrentPage != 1) {
      sentCurrentPage--;
      this.setState({ currentPage: sentCurrentPage });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  columns = [
    {
      path: "itemCode",
      label: "Item Code",
      content: (ItemSelected) => (
        <Link
          to={`/itemScanform/new/${encodeURIComponent(
            ItemSelected.itemCode.replace("#", "%23")
          )}/${this.state.passedStockTakeID}`}
        >
          <h6>{ItemSelected.itemCode}</h6>
        </Link>
      ),
    },
    { path: "crossReference", label: "Cross Reference" },
  ];

  render() {
    let {
      currentPage,
      dataRetreived,
      fullname,
      searchQuery,
      sortColumn,
      pageSize,
      color,
      cssOverride,
      loading,
      userID,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const allEntriesTotal = filtered.length;

    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <NavbarEH />

        <div className="container container-top-70">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="row align-start-horizontal">
              <div data-aos="fade-right ">
                <span className="button-padding">
                  <Link to={`/pageKioskSelect/${userID}`} className="btn p-0">
                    <ArrowLeft color="#bae8e6" size={30} />
                  </Link>
                </span>
                <span className="display-name">{fullname}</span>
                <span className="display-name-heading">
                  {" "}
                  / Cross References
                </span>
              </div>
            </div>

            <div className="col" data-aos="fade-right">
              <Link
                to="/stocktakeNameForm/new"
                className="btn button-color-babyblue button-margin-all"
              >
                New Cross Reference
              </Link>
            </div>
            <div className=" col searchBar-width-300" data-aos="fade-left">
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch}
                placeholder="Search Item codes..."
              />
            </div>
          </div>
          {/* Place cross reference table here. */}
          {filtered == "No Data Retreived" ? (
            "No Data"
          ) : (
            <CrossReferenceTable
              currentPage={currentPage}
              filtereddata={filtered}
              onPageDecrement={this.handlePageDecrement}
              onPageIncrement={this.handlePageIncrement}
              onPageChange={this.handlePageChange}
              onSort={this.handleSort}
              pageSize={pageSize}
              sortColumn={sortColumn}
              allEntriesTotal={allEntriesTotal}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PageCrossReference;
