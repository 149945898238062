import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarED from "./components/navbarED";
import withMyHook from "./common/withMyHook";

// Services
import httpService from "./services/httpService.js";
import config from "../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";
//Other
import {
  ArrowLeft,
  GraphUpArrow,
  JournalPlus,
  ClipboardMinusFill,
} from "react-bootstrap-icons";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class PageEDashboard extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    fullname: "",
    userID: "",
    kioskname: "",

    // Arrays
    kioskArray: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const kioskID = this.state.myParams.id;

    // Get a list of the kiosk details by kiosk id
    let { data: KioskData } = await httpService.get(
      config.apiKioskDetails + "?id=" + kioskID
    );

    let kioskname = KioskData.name;

    // set variables
    localStorage.setItem("kioskID", kioskID);
    localStorage.setItem("kioskName", kioskname);
    localStorage.setItem("StocktakeID", "");
    localStorage.setItem("HolderID", "");
    localStorage.setItem("CounterID", "");

    // Get variables
    let userID = localStorage.getItem("userID");
    let fullname = localStorage.getItem("fullname");

    this.setState({ userID, fullname, kioskArray: KioskData, kioskname });
  }

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const { color, cssOverride, fullname, kioskname, loading, userID } =
      this.state;

    return (
      <React.Fragment>
        <NavbarED />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div data-aos="fade-right align-start-horizontal">
              <span className="button-padding">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("Back to select kiosk")}
                >
                  <Link
                    to={`/PageKioskSelect/${userID}`}
                    className="btn p-0 border-none"
                  >
                    <ArrowLeft color="#bae8e6" size={30} />
                  </Link>
                </OverlayTrigger>
              </span>
              <span className="display-name">{kioskname}</span>
            </div>
          </div>
          <div className="row kioskList">
            <div className="px-2  text-center">
              <h1 className="display-7 fw-bold">{kioskname} Stock Takes</h1>
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                  Quickly scan or count stock items and match them against the
                  imported Navision stock Takes. Search the scans and analyse
                  the results. Create Kiosk stock history to troubleshoot
                  variances. Select an option below by clicking the icon.
                </p>
              </div>
              <div></div>
            </div>
          </div>

          <div className="row align-center-all">
            <div className="card-body icon-button-size">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Count items of stock")}
              >
                <Link classname="a-ammended " to="/pageItemScan">
                  <div className="align-center-all">
                    <span>
                      <JournalPlus color="#bae8e6" size={45} />
                    </span>
                    <span className="p-2"> Count</span>
                  </div>
                </Link>
              </OverlayTrigger>
            </div>

            <div className="card-body icon-button-size">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Clear counted items")}
              >
                <Link classname="a-ammended " to="/PageClearScans">
                  <div className="align-center-all">
                    <span>
                      <ClipboardMinusFill color="#bae8e6" size={45} />
                    </span>
                    <span className="p-2"> Clear</span>
                  </div>
                </Link>
              </OverlayTrigger>
            </div>
            <div className="card-body icon-button-size">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("View stock take results")}
              >
                <Link classname="a-ammended " to="/PageSTResults">
                  <div className="align-center-all">
                    <span>
                      <GraphUpArrow color="#bae8e6" size={45} />
                    </span>
                    <span className="p-2"> Results</span>
                  </div>
                </Link>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageEDashboard);
