import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarSTDD from "./components/navbarSTDD";
import withMyHook from "./common/withMyHook";

// Services
import httpService from "./services/httpService.js";
import config from "../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";
//Other
import {
  ArrowLeft,
  JournalPlus,
  ClipboardMinusFill,
} from "react-bootstrap-icons";

class PageEDashboard extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    fullname: "",
    userID: "",
    kioskname: "",
    authID: "",

    // Arrays
    kioskArray: [],

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {
    const kioskID = this.state.myParams.id;

    // Get a list of the kiosk details by kiosk id
    let { data: KioskData } = await httpService.get(
      config.apiKioskDetails + "?id=" + kioskID
    );

    let kioskname = KioskData.name;

    // set variables
    localStorage.setItem("kioskID", kioskID);
    localStorage.setItem("kioskName", kioskname);
    localStorage.setItem("StocktakeID", "");
    localStorage.setItem("HolderID", "");
    localStorage.setItem("CounterID", "");

    // Get variables
    let userID = localStorage.getItem("userID");
    let fullname = localStorage.getItem("fullname");
    const authID = localStorage.getItem("authID");

    this.setState({
      authID,
      userID,
      fullname,
      kioskArray: KioskData,
      kioskname,
    });
  }

  render() {
    const { authID, color, cssOverride, fullname, kioskname, loading, userID } =
      this.state;

    let gotopage = "";

    // executive user
    if (authID == 1) {
      gotopage = "/PageKioskSelect/";
    }

    // standard user
    if (authID == 3) {
      gotopage = "/pageKioskSelectSTD/";
    }

    return (
      <React.Fragment>
        <NavbarSTDD />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div data-aos="fade-right align-start-horizontal">
              <span className="button-padding">
                <Link to={`${gotopage}${userID}`} className="btn p-0">
                  <ArrowLeft color="#bae8e6" size={30} />
                </Link>
              </span>
              <span className="display-name">{kioskname}</span>
            </div>
          </div>
          <div className="row kioskList">
            <div className="px-2  text-center">
              <h1 className="display-7 fw-bold">{kioskname} Stock Takes</h1>
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                  Quickly scan and capture stock items. Easily search the stock
                  book or search the items already counted, to ensure the
                  information is correct. Select an option below by clicking the
                  icon.
                </p>
              </div>
              <div></div>
            </div>
          </div>

          <div className="row align-center-all">
            <div className="card-body icon-button-size">
              <Link classname="a-ammended " to="/pageItemScan">
                <div className="align-center-all">
                  <span>
                    <JournalPlus color="#bae8e6" size={45} />
                  </span>
                  <span className="p-2"> Count</span>
                </div>
              </Link>
            </div>

            <div className="card-body icon-button-size">
              <Link classname="a-ammended " to="/PageClearScans">
                <div className="align-center-all">
                  <span>
                    <ClipboardMinusFill color="#bae8e6" size={45} />
                  </span>
                  <span className="p-2"> Clear</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageEDashboard);
