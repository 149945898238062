import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import Navbar from "./components/navbar";
import SearchBox from "./common/searchBox";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

// Utilities
import _ from "lodash";
import { ToastContainer, Zoom } from "react-toastify";

//common
import Table from "./common/table";

class PageItemSearchTable extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    passedStockTakeID: "",

    sortColumn: { path: "itemCode", order: "asc" },
    searchQuery: "",
    searchQueryDesc: "",
    dataRetreived: [],
  };

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  async componentDidMount() {
    this.on();
    // Get the selected StockTake ID
    let passedStockTakeID = localStorage.getItem("StocktakeID");

    // Get data by entity ID
    let { data: Dbdata } = await httpService.get(config.apiStockItemsGet);

    this.setState({
      dataRetreived: Dbdata,
      passedStockTakeID: passedStockTakeID,
    });
    this.off();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn: sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
    });
  };

  handleSearchDesc = (query) => {
    this.setState({
      searchQueryDesc: query,
    });
  };

  columns = [
    {
      path: "itemCode",
      label: "Item Code",
      content: (ItemSelected) => (
        <Link
          to={`/itemScanform/new/${encodeURIComponent(
            ItemSelected.itemCode.replace("#", "%23")
            // ItemSelected.itemCode.replace("+", "%2B")
          )}/${this.state.passedStockTakeID}`}
        >
          <h6>{ItemSelected.itemCode}</h6>
        </Link>
      ),
    },
    { path: "description", label: "Description" },
    { path: "productGroupCode", label: "Grouping Code" },
  ];

  render() {
    const {
      color,
      cssOverride,
      loading,
      dataRetreived,
      searchQueryDesc,
      searchQuery,
      sortColumn,
    } = this.state;

    const sortedData = _.orderBy(
      dataRetreived,
      [sortColumn.path],
      [sortColumn.order]
    );

    // filter the data from search input contents
    let filtered = sortedData;
    if (searchQuery)
      filtered = sortedData.filter((m) =>
        m.itemCode.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    if (searchQueryDesc)
      filtered = sortedData.filter((m) =>
        m.description.toLowerCase().includes(searchQueryDesc.toLowerCase())
      );

    return (
      <React.Fragment>
        <Navbar />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Retrieving Data...
            </div>
          </div>

          <ToastContainer
            draggable={true}
            transition={Zoom}
            autoClose={200}
            position="top-center"
          />

          <div className="row">
            <div>
              <h2>Item Search</h2>
            </div>
            <form>
              <div className="align-buttons align-center-all ">
                <div>
                  <Link
                    to={"/pageItemScan"}
                    className="btn  btn-sm px-3 button-color-babyblue margin-R-5"
                    style={{ marginBottom: 10, marginTop: 10 }}
                  >
                    Back
                  </Link>
                </div>
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search Item code ..."
                />
                <SearchBox
                  value={searchQueryDesc}
                  onChange={this.handleSearchDesc}
                  placeholder="Search Description ..."
                />
              </div>
            </form>

            <div className="anyClass overflow-auto padding-all-4 ">
              <Table
                columns={this.columns}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                data={filtered}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageItemSearchTable;
