import React from "react";

//Components
import Navbar from "./components/navbar";

//Other
import _ from "lodash";

// Services
import withMyHook from "./../pages/common/withMyHook";

//Services

//Utilites
import { paginate } from "../pages/utils/paginate";

class PageHome extends React.Component {
  state = {
    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container">
          <div className="px-4 py-5 my-5 text-center">
            {/* <img
              className="d-block mx-auto mb-4"
              src="/docs/5.0/assets/brand/bootstrap-logo.svg"
              alt=""
              width="72"
              height="57"
            /> */}
            <h1 className="display-5 fw-bold">MEMI Kiosk Stock Takes</h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">
                Quickly scan and capture stock items and match them against the
                imported Navision stock Takes. Search scans and analyse the
                results. Create Kiosk stock history to troubleshoot.
              </p>
              {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 gap-3"
                >
                  Primary button
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-lg px-4"
                >
                  Secondary
                </button>
              </div> */}
            </div>
            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageHome);
