import React, { Component } from "react";
import { Link } from "react-router-dom";
//Components

import NavbarEH from "./components/navbarEH";
import DeleteStockTakeItems from "./components/deleteStockTakeItems";

// Services
import httpService from "./services/httpService.js";
import config from "../config.json";

import { ArrowLeft } from "react-bootstrap-icons";

class PageDeleteImport extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,
    passedkioskID: "",
    passedStockTakeID: "",
    kioskID: "",
    kioskName: "",
    authID: "",
    userID: "",
    fullname: "",

    STdataRetreived: [],
    KIOSKdataRetreived: [],
    KIOSKCOUNTERdataRetreived: [],
  };

  async componentDidMount() {
    const kioskID = localStorage.getItem("kioskID");
    const kioskName = localStorage.getItem("kioskName");
    // const authID = localStorage.getItem("authID");

    const userID = localStorage.getItem("userID");
    const fullname = localStorage.getItem("fullname");

    // Get all the current uncompleted stocktakes
    let { data: Dbdata } = await httpService.get(
      //   config.apiStockTakesIDGet + "?id=" + kioskID
      config.apiStockTakesGet
    );
    this.setState({
      //   authID,
      STdataRetreived: Dbdata,
      kioskName,
      kioskID,
      userID,
      fullname,
    });
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    const { fullname, kioskName, STdataRetreived, userID } = this.state;

    return (
      <React.Fragment>
        <NavbarEH />
        <div className="container">
          <div className="row align-start-horizontal">
            <div data-aos="fade-right ">
              <span className="button-padding">
                <Link to={`/pageKioskSelect/${userID}`} className="btn p-0">
                  <ArrowLeft color="#bae8e6" size={30} />
                </Link>
              </span>
              <span className="display-name">{fullname}</span>
              <span className="display-name-heading">
                {" "}
                / Delete imported stock take Items
              </span>
            </div>
          </div>
        </div>
        {STdataRetreived == "" ? (
          <div className=" row nodata-text p-5 ">
            There are no active stock takes loaded for {kioskName}
          </div>
        ) : (
          <DeleteStockTakeItems STdataRetreived={STdataRetreived} />
        )}
      </React.Fragment>
    );
  }
}

export default PageDeleteImport;
