import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useEffect } from "react";

import OneImage from "./../../images/One.png";
import TwoImage from "./../../images/Two.png";
import ThreeImage from "./../../images/Three.png";
import FourImage from "./../../images/Four.png";

import { ArrowLeft } from "react-bootstrap-icons";

function ImportCSV({ onCSVSubmit, on, off, STdataRetreived }) {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [disabledVar, setDisabled] = useState(true);
  const [disabledVar2, setDisabled2] = useState(true);
  const [disabledVar3, setDisabled3] = useState(true);
  const [csvType, setCSVType] = useState();
  const [stockTakeID, setStockTakeID] = useState();
  const [fullname, setFullname] = useState();
  const [userID, setUserID] = useState();

  const fileReader = new FileReader();

  useEffect(() => {
    // Update the document title using the browser API
    setFullname(localStorage.getItem("fullname"));
    setUserID(localStorage.getItem("userID"));
  }, []);

  const handleOnChange = (e) => {
    const emptyArray = [];
    setArray(emptyArray);
    setFile(e.target.files[0]);
  };

  const handleSelect = () => {
    // get the Value of the select the user has chosen
    var e = document.getElementById("TypeSelect");
    var e2 = document.getElementById("StockTakeSelect");

    // Get the value of the selected option
    let importType = e.value;
    let StockTakeSelectID = e2.value;

    //Get the text of the selected option
    var text = e.options[e.selectedIndex].text;
    var text2 = e2.options[e2.selectedIndex].text;

    // set the table array to blank
    const emptyArray = [];
    setArray(emptyArray);

    //if the import type is 2 - Navision stock take import

    if (importType == 2) {
      setDisabled3(false);
      setStockTakeID(StockTakeSelectID);
    } else {
      setDisabled3(true);
    }

    // set CSV Type
    setCSVType(text);

    // If the user has not selected anything
    if (importType == 0) {
      const emptyArray = [];
      setArray(emptyArray);
      setDisabled2(true);
      setDisabled(true);
    } else {
      setDisabled2(false);
    }
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
    setDisabled(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    on();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
    off();
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <div className="container">
      <div className="row">
        <div>
          <div className="row align-start-horizontal">
            <div data-aos="fade-right ">
              <span className="button-padding">
                <Link to={`/pageKioskSelect/${userID}`} className="btn p-0">
                  <ArrowLeft color="#bae8e6" size={30} />
                </Link>
              </span>
              <span className="display-name">{fullname}</span>
              <span className="display-name-heading"> / CSV file imports</span>
            </div>
          </div>

          <form>
            <div className="align-buttons"></div>
            <div className="align-buttons">
              <img src={OneImage} height="20px" width="20px" alt="" />

              <select
                className="form-select textfeild-size-300 button-margin-all"
                onChange={handleSelect}
                id="TypeSelect"
              >
                <option defaultValue value="0">
                  Select import type...
                </option>
                <option value="1">ME.MI Stock Items</option>
                <option value="2">Navision Stock Take</option>
                <option value="3">Navision Cross references</option>
              </select>

              <img src={TwoImage} height="20px" width="20px" alt="" />
              <select
                className="form-select textfeild-size-350 button-margin-all"
                onChange={handleSelect}
                id="StockTakeSelect"
                disabled={disabledVar3}
              >
                <option defaultValue>Select Stock Take...</option>
                {STdataRetreived &&
                  STdataRetreived.map((STdataRetreivedSingle) => (
                    <option
                      key={STdataRetreivedSingle.id}
                      value={STdataRetreivedSingle.id}
                    >
                      {`${STdataRetreivedSingle["stocktakeName"]} (${STdataRetreivedSingle["kioskName"]})`}
                    </option>
                  ))}
              </select>
              <img src={ThreeImage} height="20px" width="20px" alt="" />
              <input
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
                className="form-control textfeild-size-300 button-margin-all"
              />

              <button
                type="button"
                className="btn button-color-babyblue button-margin-all"
                onClick={(e) => {
                  handleOnSubmit(e);
                }}
                disabled={disabledVar2}
              >
                View
              </button>
            </div>
          </form>
        </div>
        <div>
          <br />

          <div className="anyClass overflow-auto padding-all-4 ">
            <table className="table-width">
              <thead>
                <tr key={"header"} className="row-border-bottom">
                  {headerKeys.map((key) => (
                    <th>{key}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {array.map((item) => (
                  <tr key={item.id} className="row-border-bottom">
                    {Object.values(item).map((val) => (
                      <td>{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="align-buttons-end button-margin-all">
            <img src={FourImage} height="20px" width="20px" alt="" />
            <button
              type="button"
              className="btn button-color-babyblue button-margin-all"
              onClick={() => onCSVSubmit(array, csvType, stockTakeID)}
              disabled={disabledVar}
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportCSV;
