import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarED from "./components/navbarED";
import ItemScan from "./components/itemScan";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

import { ArrowLeft } from "react-bootstrap-icons";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class PageItemScan extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    passedkioskID: "",
    passedStockTakeID: "",
    StocktakeID: "",
    kioskID: "",
    kioskName: "",

    gotopage: "",
    authID: "",

    STdataRetreived: [],
    KIOSKdataRetreived: [],
    KIOSKCOUNTERdataRetreived: [],
    StockTakeNotes: [],
  };

  async componentDidMount() {
    const StocktakeID = localStorage.getItem("StocktakeID");
    const kioskID = localStorage.getItem("kioskID");
    const kioskName = localStorage.getItem("kioskName");
    const authID = localStorage.getItem("authID");

    // Get all the stock take data
    let { data: Dbdata } = await httpService.get(
      config.apiStockTakesIDGet + "?id=" + kioskID
    );
    this.setState({
      authID,
      STdataRetreived: Dbdata,
      StocktakeID: StocktakeID,
      kioskID,
      kioskName,
    });
  }

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  doSubmit = async () => {};

  render() {
    const {
      authID,
      color,
      cssOverride,
      KIOSKdataRetreived,
      kioskID,
      kioskName,
      loading,
      STdataRetreived,
      StocktakeID,
    } = this.state;

    let gotopage = "";

    // executive user
    if (authID == 1) {
      gotopage = "/PageEDashboard/";
    }

    // standard user
    if (authID == 3) {
      gotopage = "/PageSTDashboard/";
    }

    return (
      <React.Fragment>
        <NavbarED />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div data-aos="fade-right align-start-horizontal">
            <span className="button-padding">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Back to stock take options")}
              >
                <Link
                  to={`${gotopage}${kioskID}`}
                  className="btn p-0 border-none "
                >
                  <ArrowLeft color="#bae8e6" size={30} />
                </Link>
              </OverlayTrigger>
            </span>
            <span className="display-name">{kioskName}</span>
            <span className="display-name-heading"> / Item count</span>
          </div>

          {STdataRetreived == "" ? (
            <div className=" row nodata-text p-5">
              There are no active stock takes loaded for {kioskName}
            </div>
          ) : (
            <ItemScan
              on={this.on}
              off={this.off}
              STdataRetreived={STdataRetreived}
              KIOSKdataRetreived={KIOSKdataRetreived}
              StocktakeID={StocktakeID}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PageItemScan;
