import React from "react";
import { Link } from "react-router-dom";

import Joi from "joi-browser";
import Form from "./../common/form";
import withMyHook from "./../common/withMyHook";
import Navbar from "./../components/navbar";

// Services
import httpService from "./../services/httpService.js";
import config from "../../config.json";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

import { ArrowLeft } from "react-bootstrap-icons";

class ItemScanForm extends Form {
  state = {
    data: {
      itemCode: "",
      description: "",
      groupingCode: "",
      counterID: "",
      holderID: "",
      scandate: "",
      scantime: "",
      qty: "",
    },
    // Loading data variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    errors: {},
    counters: [],
    holders: [],

    passedkioskID: "",
    passedStockTakeID: "",
    passedHolderID: "",
    passedCounterID: "",

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    itemCode: Joi.string().label("Item Code"),
    description: Joi.string().label("Description"),
    groupingCode: Joi.string()
      .empty("")
      .optional()
      .allow("")
      .label("Grouping Code"),
    counterID: Joi.string().label("Counter"),
    holderID: Joi.string().label("Holder"),
    scandate: Joi.string().label("Date"),
    scantime: Joi.string().label("Time"),
    qty: Joi.string().label("Qty"),
  };

  async componentDidMount() {
    const scanItemID = this.state.myParams.id;

    // decode the item code as it may have contained a special character
    // let itemCodePassed = decodeURIComponent(this.state.myParams.itemCode);
    let itemCodePassed = this.state.myParams.itemCode;

    const stocktakeIDPassed = this.state.myParams.stocktakeID;

    this.state.passedkioskID = localStorage.getItem("kioskID");
    this.state.passedStockTakeID = localStorage.getItem("StocktakeID");

    // Now pre-load the selects with the correct counters and holders

    // If the Scanned item has been passed then get the Kiosk counter and Holder info to populate the selects
    // Else get the Counter annd Holder from the Scanned ID
    if (scanItemID == "new") {
      let { data: CountersData } = await httpService.get(
        config.apiCountersGet + "?id=" + this.state.passedkioskID
      );

      this.setState({
        counters: CountersData,
        passedStockTakeID: stocktakeIDPassed,
      });

      // itemCodePassed = "AMATAG-9Y-YC-#LX-50";
      // Now use the itemCode Passed id to get and populate the Stock Item fields.
      let { data: ItemCodeData } = await httpService.get(
        config.apiStockItemNewGet + "?id=" + itemCodePassed
      );
      this.setState({ data: this.mapToViewModel(ItemCodeData) });
    } else {
      let { data: retreivedData } = await httpService.get(
        config.apiItemScannedSingleGet + "?id=" + scanItemID
      );

      let { data: CountersData } = await httpService.get(
        config.apiCountersGet + "?id=" + retreivedData.kioskID
      );

      let { data: Holders } = await httpService.get(
        config.apiCounterHoldersGet + "?id=" + retreivedData.CounterID
      );

      this.setState({
        counters: CountersData,
        holders: Holders,
      });

      if (!retreivedData) return this.state.myNavigate("/not-found");
      this.setState({ data: this.mapToViewModel(retreivedData) });
    }
  }

  doSubmit = async () => {
    const ID = this.state.myParams.id;
    const stocktakeIDPassed = this.state.myParams.stocktakeID;
    const kioskID = localStorage.getItem("kioskID");

    if (ID === "new") {
      // Add a new entry

      if (
        this.state.data.counterID == null ||
        this.state.data.holderID == null ||
        this.state.data.counterID == "Select..." ||
        this.state.data.holderID == "Select..."
      ) {
        toast.error("Fill in the counter and or holder");
      } else {
        // Get date and time for the submision
        let scanDate = new Date().toISOString().slice(0, 10);

        let today = new Date();
        let scanTime =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();

        let dataToSend = this.state.data;
        let formData = new FormData();

        // // add these
        formData.append("kioskID", kioskID);
        formData.append("StockTakeID", stocktakeIDPassed);
        formData.append("scanDate", scanDate);
        formData.append("scanTime", scanTime);

        Object.keys(dataToSend).forEach((key) => {
          formData.append(key, dataToSend[key]);
        });
        try {
          await httpService.post(config.apiItemScanManualAdd, formData);
        } catch (error) {
          if (error.reponse && error.reponse.status === 404)
            toast.error("Something failed while adding.");
        }
        this.state.myNavigate("/pageItemScan");
      } // close if else holder counter == ''
    } else {
      // Update an entry
      let dataToSend = this.state.data;
      let formData = new FormData();
      //add the Id to the data to send to db
      formData.append("id", ID);
      //loop through the data and add to from data
      Object.keys(dataToSend).forEach((key) => {
        formData.append(key, dataToSend[key]);
      });
      try {
        await httpService.post(config.apiItemScannedSingleUpdate, formData);
      } catch (error) {
        if (error.reponse && error.reponse.status === 404)
          toast.error("Something failed while updating.");
      }
    }
    this.state.myNavigate("/pageItemScan");
  };

  //Submit a delete
  doDelete = async () => {
    const ID = this.state.myParams.id;

    try {
      const deleteReply = await httpService.get(
        config.apiItemScanDelete + "?id=" + ID
      );
      var myObject = JSON.parse(deleteReply);
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while deleting.");
    }
    this.state.myNavigate("/pageItemScan");
  };

  doClear = () => {
    let data = {
      itemCode: "",
      description: "",
      productGroupCode: "",
      CounterID: "",
      holderID: "",
      scanDate: "",
      scanTime: "",
      qty: "",
    };

    this.setState({ data: this.mapToViewModel(data) });
    this.state.myParams.id = "new";
    this.state.myNavigate("/itemScanform");
  };

  mapToViewModel(data) {
    // get date and time for the submision
    let scanDate = new Date().toISOString().slice(0, 10);

    let today = new Date();
    let scanTime =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    if (!data.scanDate) {
      data.scanDate = scanDate;
    }
    if (!data.scanTime) {
      data.scanTime = scanTime;
    }
    if (!data.qty) {
      data.qty = "1";
    }

    return {
      itemCode: data.itemCode,
      description: data.description,
      groupingCode: data.productGroupCode,
      counterID: data.CounterID,
      holderID: data.holderID,
      scandate: data.scanDate,
      scantime: data.scanTime,
      qty: data.qty,
    };
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    const { color, cssOverride, loading } = this.state;
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <Navbar />
        <div className="container align-center-all">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>
          <div className="row">
            <div className="form-container clearscans-style mt-2 ">
              <form onSubmit={this.handleSubmit} id="formID">
                <table className="table table-group">
                  <thead>
                    <tr>
                      <th>
                        <span className="button-padding">
                          <Link to={"/pageItemScan"} className="btn p-0">
                            <ArrowLeft color="#bae8e6" size={30} />
                          </Link>
                        </span>
                      </th>
                      <th>
                        <h4>Item Scan</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Item Code</td>
                      <td className="p-1">
                        {this.renderInput("itemCode", "Item Code", "true")}
                      </td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td className="p-1">
                        {this.renderInput("description", "Description", "true")}
                      </td>
                    </tr>
                    <tr>
                      <td>Grouping Code</td>
                      <td className="p-1">
                        {this.renderInput(
                          "groupingCode",
                          "Grouping Code",
                          "true"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Counter</td>
                      <td className="p-1">
                        {this.renderSelect(
                          "counterID",
                          "counterName",
                          this.state.counters,
                          ""
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>Holder</td>
                      <td className="p-1">
                        {this.renderSelect(
                          "holderID",
                          "holder_name",
                          this.state.holders,
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td className="p-1">
                        {this.renderInput("scandate", "Date", "true")}
                      </td>
                    </tr>
                    <tr>
                      <td>Time</td>
                      <td className="p-1">
                        {this.renderInput("scantime", "Time", "true")}
                      </td>
                    </tr>
                    <tr>
                      <td>Qty</td>
                      <td className="p-1">
                        {this.renderInput("qty", "Qty", "true")}
                      </td>
                    </tr>

                    <tr align="center">
                      <td colSpan={2}>
                        <span className="button-padding">
                          {this.renderSaveButton("Save")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonDelete("Delete")}
                        </span>
                        <span className="button-padding">
                          {this.renderButtonClear("Clear")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withMyHook(ItemScanForm);
