import axios from "axios";

//This is to handle all unexpected errors
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // Unexpected (Network down, server down. db down, bug)
    // - Log them
    // Display a generic and friendly error message
    if (error.response.status !== 500) {
      console.log("Logging the error", error);
      console.log("error.response.status = ", error.response.status);
      //if the delete fails show an alert then set posts to the origional state
      // alert('An unexpected error occured.');
      // toast.error('An unexpected error occured.'+error);
    }
  }

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
