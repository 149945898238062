import React, { Component } from "react";
import { Link } from "react-router-dom";

//Components
import NavbarED from "./components/navbarED";
import StockTakeResults from "./components/stockTakeResults";

// Services
import httpService from "./services/httpService.js";
import config from "./../config.json";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

import { ArrowLeft } from "react-bootstrap-icons";

class PageSTResults extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,
    passedkioskID: "",
    passedStockTakeID: "",
    kioskID: "",
    kioskName: "",

    STdataRetreived: [],
    KIOSKdataRetreived: [],
    KIOSKCOUNTERdataRetreived: [],
  };

  async componentDidMount() {
    const kioskID = localStorage.getItem("kioskID");
    const kioskName = localStorage.getItem("kioskName");

    // Get all the stock take data
    let { data: Dbdata } = await httpService.get(
      config.apiStockTakesIDGet + "?id=" + kioskID
    );
    this.setState({
      STdataRetreived: Dbdata,
      kioskID,
      kioskName,
    });
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  // doSubmit = async () => {};

  render() {
    const {
      color,
      cssOverride,
      KIOSKdataRetreived,
      loading,
      STdataRetreived,
      kioskID,
      kioskName,
    } = this.state;

    return (
      <React.Fragment>
        <NavbarED />
        <div className="container">
          <div id="overlay" className="overlay">
            <div id="text" className="text">
              <PuffLoader
                color={color}
                loading={loading}
                cssOverride={cssOverride}
                size={50}
              />
              Uploading Data...
            </div>
          </div>

          <div data-aos="fade-right align-start-horizontal p-5">
            <span className="button-padding">
              <Link to={`/PageEDashboard/${kioskID}`} className="btn p-0">
                <ArrowLeft color="#bae8e6" size={30} />
              </Link>
            </span>
            <span className="display-name">{kioskName}</span>
            <span className="display-name-heading"> / Results</span>
          </div>
          {STdataRetreived == "" ? (
            <div className=" row nodata-text p-5">
              There are no active stock takes loaded for {kioskName}
            </div>
          ) : (
            <StockTakeResults
              on={this.on}
              off={this.off}
              STdataRetreived={STdataRetreived}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PageSTResults;
