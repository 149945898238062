import React, { Component } from "react";
import { Link } from "react-router-dom";
//Components

import NavbarED from "./components/navbarED";
import ClearScans from "./components/clearScans";

// Services
import httpService from "./services/httpService.js";
import config from "../config.json";

import { ArrowLeft } from "react-bootstrap-icons";

class PageClearScans extends Component {
  state = {
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,
    passedkioskID: "",
    passedStockTakeID: "",
    kioskID: "",
    kioskName: "",
    authID: "",

    STdataRetreived: [],
    KIOSKdataRetreived: [],
    KIOSKCOUNTERdataRetreived: [],
  };

  async componentDidMount() {
    const kioskID = localStorage.getItem("kioskID");
    const kioskName = localStorage.getItem("kioskName");
    const authID = localStorage.getItem("authID");

    // Get all the current uncompleted stocktakes
    let { data: Dbdata } = await httpService.get(
      config.apiStockTakesIDGet + "?id=" + kioskID
    );
    this.setState({
      authID,
      STdataRetreived: Dbdata,
      kioskName,
      kioskID,
    });
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }



  render() {
    const { authID, kioskName, STdataRetreived, kioskID } = this.state;

    let gotopage = "";

    // executive user
    if (authID == 1) {
      gotopage = "/PageEDashboard/";
    }

    // standard user
    if (authID == 3) {
      gotopage = "/PageSTDashboard/";
    }

    return (
      <React.Fragment>
        <NavbarED />
        <div className="container">
          <div data-aos="fade-right align-start-horizontal">
            <span className="button-padding">
              <Link to={`${gotopage}${kioskID}`} className="btn p-0">
                <ArrowLeft color="#bae8e6" size={30} />
              </Link>
            </span>
            <span className="display-name">{kioskName}</span>
            <span className="display-name-heading"> / Clear counts</span>
          </div>
        </div>
        {STdataRetreived == "" ? (
          <div className=" row nodata-text p-5">
            There are no active stock takes loaded for {kioskName}
          </div>
        ) : (
          <ClearScans STdataRetreived={STdataRetreived} />
        )}
      </React.Fragment>
    );
  }
}

export default PageClearScans;
