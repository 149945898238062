import React from "react";
import { Link } from "react-router-dom";

//common
import Table from "../common/table";
import Pagination from "../common/pagination";
import Excelexport from "../common/excelexport";

//Utilites
import { paginate } from "../utils/paginate";

class ItemSearchTable extends React.Component {
  // id: "11",
  // itemCode: "Z00150-0-0",
  // description: "Gold Jewel Sparkle 225ml",
  // unitCost: "46.58",
  // productGroupCode: "JEWEL SPARKEL",
  // StockTakeID: null,
  // CounterID: "22",
  // holderID: "251",
  // scanDate: "2022/11/18",
  // scanTime: "11:45:00",
  // qty: "1",
  // countertDesc: "No Allocation",
  // holderDesc: "No Allocation",
  // kioskID: "17"

  columns = [
    { path: "itemCode", label: "Item Code" },
    { path: "description", label: "Description" },
    // { path: "kioskName", label: "Kiosk" },
    { path: "scanDate", label: "Date" },
    // { path: "scanTime", label: "Time" },
    { path: "countertDesc", label: "Counter" },
    { path: "holderDesc", label: "Holder" },
    { path: "qty", label: "Qty" },
    { path: "stocktakeName", label: "Stock Take Name" },
    // { path: "stocktakeType", label: "Type" },
    // { path: "stocktakePeriod", label: "Period" },
  ];

  render() {
    const {
      allEntriesTotal,
      currentPage,
      filtereddata,
      onDelete,
      onPageChange,
      onPageDecrement,
      onPageIncrement,
      onSort,
      pageSize,
      sortColumn,
    } = this.props;

    const dataPag = paginate(filtereddata, currentPage, pageSize);

    if (filtereddata.length > 0) {
      return (
        <React.Fragment>
          <div>
            <Pagination
              itemsCount={allEntriesTotal}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={onPageChange}
              onPageDecrement={onPageDecrement}
              onPageIncrement={onPageIncrement}
            />
          </div>
          <div className="table-responsive">
            <Table
              columns={this.columns}
              sortColumn={sortColumn}
              onSort={onSort}
              data={dataPag}
              onDelete={onDelete}
            />
          </div>
          <div className="align-end-all">
            {filtereddata && (
              <Excelexport
                excelData={filtereddata}
                fileName={"Items Count History"}
                tabname={"History"}
              />
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="row align-center-all">
          <div className="card kioskcard m-2 align-center-all mt-5">
            <div className="card-body kioskcard-style">
              <h6 className="card-subtitle text-muted">No Data retreived</h6>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ItemSearchTable;
