import React from "react";
import Form from "./common/form";

//Other
import _ from "lodash";

// Services
import httpService from "./../pages/services/httpService";
import config from "./../config.json";
import withMyHook from "./../pages/common/withMyHook";

//Utilites
import Joi from "joi-browser";
import { ToastContainer, Zoom, toast } from "react-toastify";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

class PageLogin extends Form {
  state = {
    data: {
      userCell: "",
      userPassword: "",
    },

    firstName: "",
    surName: "",
    userID: "",

    // Loading data variables
    color: "#002072",
    cssOverride: { display: "block", margin: "0 auto", borderColor: "#002072" },
    speedMultiplier: 1,
    loading: true,

    // Arrays
    userDetails: [],
    kioskDetails: [],

    // Other
    errors: {},

    myParams: this.props.myParams,
    myNavigate: this.props.myNavigate,
  };

  schema = {
    userCell: Joi.string().label("Cell Number"),
    userPassword: Joi.string().label("Password"),
  };

  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  componentDidMount() {
    localStorage.setItem("userID", "");
    localStorage.setItem("authID", "");
    localStorage.setItem("fullname", "");
    localStorage.setItem("StocktakeID", "");
    localStorage.setItem("HolderID", "");
    localStorage.setItem("CounterID", "");
  }

  doSubmit = async () => {
    let userID = "";
    let authID = "";
    let fullname = "";

    let dataToSend = this.state.data;
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      formData.append(key, dataToSend[key]);
    });

    try {
      const { data: userDetails } = await httpService.post(
        config.apiLogin,
        formData
      );
      userID = userDetails.id;
      authID = userDetails.authID;
      fullname = userDetails.firstName + " " + userDetails.surname;
    } catch (error) {
      if (error.reponse && error.reponse.status === 404)
        toast.error("Something failed while adding.");
    }

    if (userID) {
      localStorage.setItem("userID", userID);
      localStorage.setItem("authID", authID);
      localStorage.setItem("fullname", fullname);

      // based on the auth ID re-route to the correct screen.
      if (authID == 1) {
        // this is an executive login
        this.state.myNavigate(`/pageKioskSelect/${userID}`);
      }
      if (authID == 3) {
        // this is an standard user login
        this.state.myNavigate(`/pageKioskSelectSTD/${userID}`);
      }
    } else {
      toast.error("Login details not matched");
    }
  }; //close do submit

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  render() {
    const { color, cssOverride, excelExportData, loading } = this.state;
    return (
      <React.Fragment>
        <ToastContainer
          draggable={true}
          transition={Zoom}
          autoClose={2000}
          position="top-center"
        />
        <div id="overlay" className="overlay">
          <div id="text" className="text">
            <PuffLoader
              color={color}
              loading={loading}
              cssOverride={cssOverride}
              size={50}
            />
            Processing...
          </div>
        </div>
        <section className="text-center text-lg-start">
          <div className="container py-4">
            <div className="row g-0 align-items-center">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="card login-background cascading-right">
                  <div className="card-body p-5 shadow-5 text-center">
                    <h2 className="fw-bold mb-5">MEMI Kiosk Stock Manager</h2>
                    <form>
                      <div className="form-outline mb-2">
                        {this.renderInput("userCell", "Usercell", "false")}
                        <label className="form-label">Cell Number</label>
                      </div>

                      <div className="form-outline mb-2  ">
                        {this.renderInput("userPassword", "Password", "false")}
                        <label className="form-label">Password</label>
                      </div>

                      <div className="col" data-aos="fade-right">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={this.renderTooltip(
                            "Validate your login details"
                          )}
                        >
                          <button
                            type="button"
                            className="btn button-color-babyblue button-margin-all button-size-100 "
                            onClick={(e) => {
                              this.doSubmit(e);
                            }}
                          >
                            Login
                          </button>
                        </OverlayTrigger>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-5 mb-lg-0">
                <img
                  src={require("./../images/login.jpg")}
                  className="w100 rounded-4 shadow-4 img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withMyHook(PageLogin);
