import React from "react";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const SearchBox = ({ value, onChange, placeholder }) => {
  const renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('Use this field to "' + placeholder + '"')}
    >
      <input
        text="text"
        name="query"
        className="form-control margin-TB-5 margin-LR-2"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </OverlayTrigger>
  );
};
export default SearchBox;
