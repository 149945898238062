import React from "react";

const Input = ({ name, label, error, disabled, ...rest }) => {
  if (disabled == "true") {
    return (
      <div>
        <input
          {...rest}
          name={name}
          id={name}
          className="form-control input-color"
          disabled
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  } else {
    return (
      <div>
        <input
          {...rest}
          name={name}
          id={name}
          className="form-control input-color"
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }
};

export default Input;
