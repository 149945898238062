import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class kioskCard extends Component {
  renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  render() {
    const { kioskData } = this.props;
    let gotopage = "";
    const authID = localStorage.getItem("authID");

    if (authID == "3") {
      gotopage = "/PageSTDashboard/";
    }
    if (authID == "1") {
      gotopage = "/PageEDashboard/";
    }

    return (
      <div className="card kioskcard m-2">
        <div className="card-body kioskcard-style">
          <h5 className="kioskcard-heading">{kioskData.kioskName}</h5>
          <h6 className="card-subtitle mb-2 text-muted">
            {kioskData.kioskLocation}
          </h6>

          <div className="align-buttons-end button-margin-all">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={this.renderTooltip("Select the kiosk")}
            >
              <Link to={`${gotopage}${kioskData.kioskID}`}>
                <ArrowRight color="#bae8e6" size={30} />
              </Link>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }
}

export default kioskCard;
