import React, { Component } from "react";
import { Link } from "react-router-dom";
import MemiLogo from "./../../images/logo_version_3.png";

class navbar extends Component {
  state = { userID: "" };

  componentDidMount() {
    const userID = localStorage.getItem("userID");
    this.setState({ userID });
  }

  render() {
    const { userID } = this.state;
    return (
      <div>
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand nav-link active" to="/">
              ME.MI Stock Manager Ⓒ
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className=" nav-link active"
                    to={`/PageKioskSelectSTD/${userID}`}
                  >
                    Kiosks
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Stocktakes
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className=" nav-link active" to="/PageClearScans">
                        Clear Scans
                      </Link>
                    </li>
                    <li>
                      <Link className=" nav-link active" to="/pageItemScan">
                        Count / Scan
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className=" nav-link active" to="/PageSTResults">
                        Results
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link className=" nav-link active" to="/PageLogin">
                    Logout
                  </Link>
                </li>
              </ul>
              <img src={MemiLogo} height="40px" width="150px" alt="" />
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default navbar;
