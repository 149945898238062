import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

//Pages
import Pagedragdrop from "./pages/Pagedragdrop";
import PageLogin from "./pages/PageLogin";
import PageHome from "./pages/PageHome";
import PageEDashboard from "./pages/PageEDashboard";
import PageSTDashboard from "./pages/PageSTDashboard";
import PageImportCSV from "./pages/PageImportCSV";
import PageItemScan from "./pages/PageItemScan";
import PageItemSearch from "./pages/PageItemSearch";
import PageKioskSelect from "./pages/PageKioskSelect";
import PageKioskSelectSTD from "./pages/PageKioskSelectSTD";
import PageClearScans from "./pages/PageClearScans";
import PageItemSearchTable from "./pages/PageItemSearchTable";
import PageStocktake from "./pages/PageStocktake";
import PageSTResults from "./pages/PageSTResults";
import PageCrossReference from "./pages/PageCrossReference";
import PageDeleteImport from "./pages/PageDeleteImport";

//Forms
import StocktakeNameForm from "./pages/forms/stocktakeNameForm";
import ItemScanform from "./pages/forms/itemScanform";
import CrossreferenceForm from "./pages/forms/crossreferenceForm";

//Vendor Libraries
import "bootstrap/dist/js/bootstrap";

// Css
import "bootstrap/dist/css/bootstrap.css";
import "../src/css/login.css";
import "../src/css/default.css";
import "react-toastify/dist/ReactToastify.css";

//Other
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* Pages */}
        <Route path="/" element={<PageLogin />} />
        <Route path="/PageDeleteImport" element={<PageDeleteImport />} />
        <Route path="/Pagedragdrop" element={<Pagedragdrop />} />
        <Route path="/PageLogin" element={<PageLogin />} />
        <Route path="/PageImportCSV" element={<PageImportCSV />} />
        <Route path="/PageItemScan" element={<PageItemScan />} />
        <Route path="/PageItemSearch" element={<PageItemSearch />} />
        <Route path="/PageClearScans" element={<PageClearScans />} />
        <Route path="/PageEDashboard/:id" element={<PageEDashboard />} />
        <Route path="/PageSTDashboard/:id" element={<PageSTDashboard />} />
        <Route path="/PageItemSearchTable" element={<PageItemSearchTable />} />
        <Route path="/PageKioskSelect/:id" element={<PageKioskSelect />} />
        <Route
          path="/PageKioskSelectSTD/:id"
          element={<PageKioskSelectSTD />}
        />
        <Route path="/PageStocktake" element={<PageStocktake />} />
        <Route path="/PageSTResults" element={<PageSTResults />} />
        <Route path="/PageCrossReference" element={<PageCrossReference />} />

        {/* Forms */}
        <Route path="/stocktakeNameForm" element={<StocktakeNameForm />} />
        <Route path="/stocktakeNameForm/:id" element={<StocktakeNameForm />} />

        <Route path="/crossreferenceForm" element={<CrossreferenceForm />} />
        <Route
          path="/crossreferenceForm/:id"
          element={<CrossreferenceForm />}
        />
        <Route path="/itemScanform" element={<ItemScanform />} />
        <Route path="/itemScanform/:id" element={<ItemScanform />} />
        <Route
          path="/itemScanform/:id/:itemCode/:stocktakeID"
          element={<ItemScanform />}
        />
        <Route path="/" element={<PageLogin />} />
        <Route path="/*" element={<PageLogin />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
reportWebVitals();
