import { useParams, useNavigate } from "react-router-dom";

export function withMyHook(Component) {
  return function WrappedComponent(props) {
    const paramsPassed = useParams();
    const navigatePassed = useNavigate();

    return (
      <Component
        {...props}
        myParams={paramsPassed}
        myNavigate={navigatePassed}
      />
    );
  };
}

export default withMyHook;
