import React, { useState } from "react";
import { Link } from "react-router-dom";

// Services
import httpService from "./../services/httpService.js";
import config from "./../../config.json";

// Components
import SearchBox from "../common/searchBox.jsx";
import Table from "./../common/table";
import Excelexport from "./../common/excelexport";

// Utilities
import { ToastContainer, Zoom, toast } from "react-toastify";
// React Spinner
import PuffLoader from "react-spinners/PuffLoader";

// Common

import BarcodeReader from "react-barcode-reader";
import { Plus } from "react-bootstrap-icons";

// Bootstrap tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ItemScan({
  KIOSKdataRetreived,
  on,
  off,
  STdataRetreived,
  StocktakeID,
}) {
  // Variables
  const [result, setResult] = useState("No result");
  const [disabledVar, setDisabled] = useState(true);
  const [stockTakeID, setStockTakeID] = useState(null);
  const [kioskID, setkioskID] = useState(null);
  const [counterID, setcounterID] = useState(null);
  const [holderID, setholderID] = useState(null);
  const [goToPage, setgoToPage] = useState("pageItemScan");
  const [sortColumn, setsortColumn] = useState({
    path: "itemCode",
    order: "asc",
  });
  const [stockItemsScanned, setStockItemScanned] = useState([]);
  const [stockItemsToCount, setStockItemsToCount] = useState([]);
  const [totalsArray, settotalsArray] = useState([]);
  const [KIOSKCOUNTERdataRetreived, setKIOSKCOUNTERdataRetreived] = useState(
    []
  );
  const [KIOSKHOLDERdataRetreived, setKIOSKHOLDERdataRetreived] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryDesc, setSearchQueryDesc] = useState("");

  const [stockTakeNotes, setStockTakeNotes] = useState([]);

  const color = "#002072";
  const cssOverride = {
    display: "block",
    margin: "0 auto",
    borderColor: "#002072",
  };
  const speedMultiplier = 1;
  const loading = true;

  const columnsCTC = [
    {
      path: "itemCode",
      label: "Item Code",
      content: (scannedItem) => (
        <Link to="">
          <h6>{scannedItem.itemCode}</h6>
        </Link>
      ),
    },
    { path: "description", label: "Description" },

    {
      path: "",
      key: "",
      label: "Count",

      content: (scannedItem) => (
        <div>
          <Link
            onClick={() => handleCTC(scannedItem)}
            to=""
            className="btn btn-primary btn-sm"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 15,
              height: 25,
              marginBottom: 1,
              marginTop: 1,
              width: 40,
            }}
          >
            <Plus color="white" size={20} />
          </Link>
        </div>
      ),
    },
    // { path: "qty", label: "In stock" },
    { path: "scanned", label: "Counted" },
    // { path: "variance", label: "Variance" },
  ];

  const columns = [
    {
      path: "itemCode",
      label: "Item Code",
      content: (scannedItem) => (
        <Link to={`/itemScanform/${scannedItem.id}`}>
          <h6>{scannedItem.itemCode}</h6>
        </Link>
      ),
    },
    { path: "description", label: "Description" },
    // { path: "productGroupCode", label: "Grouping Code" },
    { path: "countertDesc", label: "Counter" },
    { path: "holderDesc", label: "Holder" },
    { path: "scanDate", label: "Date" },
    { path: "scanTime", label: "Time" },
    { path: "qty", label: "Qty" },
  ];

  const columnsStats = [
    { path: "displayset", label: "Counter" },
    { path: "holder", label: "Holder" },
    { path: "totalItems", label: "Total Items" },
  ];
  // Set the back button address for each user type

  //Functions

  function selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

  const handleCTC = async (data) => {
    // Go and make a scan entry to the database for saving

    if (stockTakeID && stockTakeID != "Select Stock Take...") {
      if (kioskID && kioskID != "Select the Kiosk..") {
        if (counterID && counterID != "Select the Counter...") {
          if (holderID && holderID != "Select the holder...") {
            on();

            if (data) {
              // Send the information to the Database and store.

              // get date and time for the submision
              let scanDate = new Date().toISOString().slice(0, 10);

              let today = new Date();
              let scanTime =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();

              let formData = new FormData();

              //add the Id to the data to send to db
              formData.append("StockTakeID", stockTakeID);
              formData.append("kioskID", kioskID);
              formData.append("CounterID", counterID);
              formData.append("holderID2", holderID);
              formData.append("qty", "1");
              formData.append("scanDate", scanDate);
              formData.append("scanTime", scanTime);
              formData.append("itemCode", data.id);
              formData.append("stockItemDescription", data.description);
              formData.append("prodGroupCode", "");
              formData.append("unitCost", data.unitCost);

              try {
                await httpService.post(config.apiItemScanAdd, formData);
              } catch (error) {
                if (error.reponse && error.reponse.status === 404) {
                  toast.error("Something failed while adding.");
                } else {
                  // HandleGetScannedItems(stockTakeID);
                  handleHolderSelection();
                  toast.success(data.id + " " + data.description + " saved");

                  // Update the Click to scan data
                  handleItemsToCount(stockTakeID);
                }
              }
            } else {
              toast.error("Item not found. barcode " + data);
            }
            off();
          } else {
            toast.error("Select a Holder");
          }
        } else {
          toast.error("Select a Counter");
        }
      } else {
        toast.error("Select a kiosk");
      }
    } else {
      setStockTakeID(null);
      toast.error("Select a stock take.");
    }
  };

  const handleScan = async (data) => {
    if (stockTakeID && stockTakeID != "Select Stock Take...") {
      if (kioskID && kioskID != "Select the Kiosk..") {
        if (counterID && counterID != "Select the Counter...") {
          if (holderID && holderID != "Select the holder...") {
            on();
            // Get all the stock by Item number by cross refrence code
            let { data: Dbdata } = await httpService.get(
              config.apiStockItemGet + "?id=" + data
            );

            if (Dbdata) {
              // Send the information to the Database and store.

              // get date and time for the submision
              let scanDate = new Date().toISOString().slice(0, 10);

              let today = new Date();
              let scanTime =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();

              let formData = new FormData();

              //add the Id to the data to send to db
              formData.append("StockTakeID", stockTakeID);
              formData.append("kioskID", kioskID);
              formData.append("CounterID", counterID);
              formData.append("holderID2", holderID);
              formData.append("qty", "1");
              formData.append("scanDate", scanDate);
              formData.append("scanTime", scanTime);

              Object.keys(Dbdata).forEach((key) => {
                formData.append(key, Dbdata[key]);
              });
              try {
                await httpService.post(config.apiItemScanAdd, formData);
              } catch (error) {
                if (error.reponse && error.reponse.status === 404) {
                  toast.error("Something failed while adding.");
                } else {
                  // HandleGetScannedItems(stockTakeID);
                  handleHolderSelection();
                  toast.success(
                    Dbdata.itemCode +
                      " " +
                      Dbdata.stockItemDescription +
                      " saved"
                  );

                  // Update the Click to scan data
                  handleItemsToCount(stockTakeID);
                }
              }
            } else {
              toast.error("Item not found. barcode " + data);
            }
            off();
          } else {
            toast.error("Select a Holder");
          }
        } else {
          toast.error("Select a Counter");
        }
      } else {
        toast.error("Select a kiosk");
      }
    } else {
      setStockTakeID(null);
      toast.error("Select a stock take.");
    }
  };

  const handleGetScannedItems = async (StockTakeSelectID) => {
    if (StockTakeSelectID) {
      let { data: DbdataScanns } = await httpService.get(
        config.apiItemScannedGet + "?id=" + StockTakeSelectID
      );

      if (DbdataScanns.length != 0) {
        setStockItemScanned(DbdataScanns);
      } else {
        setStockItemScanned([]);
      }

      // Now go and get scanned stats
      let { data: totalsArray } = await httpService.get(
        config.apiItemScanTotalsGet + "?StockTakeSelectID=" + StockTakeSelectID
      );

      if (totalsArray.length != 0) {
        settotalsArray(totalsArray);
      } else {
        settotalsArray([]);
      }
    } // close if stock take selected
  };

  const handleKioskSelection = async (KioskID, StockTakeSelectID) => {
    localStorage.setItem("kioskID", "");
    localStorage.setItem("CounterID", "");
    localStorage.setItem("HolderID", "");

    // Reset the counter and Holder
    setKIOSKCOUNTERdataRetreived([]);
    setKIOSKHOLDERdataRetreived([]);

    // Reset the variables
    setkioskID(null);
    setcounterID(null);
    setholderID(null);

    setkioskID(KioskID);
    localStorage.setItem("kioskID", KioskID);
    localStorage.setItem("StocktakeID", StockTakeSelectID);

    let { data: Counters } = await httpService.get(
      config.apiCountersGet + "?id=" + KioskID
    );
    if (Counters) {
      setKIOSKCOUNTERdataRetreived(Counters);
    } else {
      setKIOSKCOUNTERdataRetreived([]);
    }

    // Now get all the items that have not yet been fully scaned.
    // eg if cbar on stock take is total 2 but only one is scanned then
    // add this to the table so it can be open to click to scan.

    let { data: NotCounted } = await httpService.get(
      config.apiItemNotCountedGet + "?id=" + StockTakeSelectID
    );

    if (NotCounted) {
      setStockItemsToCount(NotCounted);
    } else {
      setStockItemsToCount([]);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleItemsToCount = async (StockTakeSelectID) => {
    // Now get all the items that have not yet been fully scaned.
    // e.g. if cbar on stock take is total 2 but only one is scanned then
    // add this to the table so it can be open to click to scan.

    let { data: NotCounted } = await httpService.get(
      config.apiItemNotCountedGet + "?id=" + StockTakeSelectID
    );

    if (NotCounted) {
      setStockItemsToCount(NotCounted);
    } else {
      setStockItemsToCount([]);
    }
  };

  const handleCounterSelection = async () => {
    // Reset the counter and Holder
    setKIOSKHOLDERdataRetreived([]);
    localStorage.setItem("CounterID", "");
    localStorage.setItem("HolderID", "");

    // Reset the variables
    setcounterID(null);
    setholderID(null);

    // Get the selected kiosk
    var e = document.getElementById("CounterSelect");

    // Get the value of the selected option
    let CounterSelectID = e.value;
    setcounterID(CounterSelectID);
    localStorage.setItem("CounterID", CounterSelectID);

    let { data: Holders } = await httpService.get(
      config.apiCounterHoldersGet + "?id=" + CounterSelectID
    );
    if (Holders) {
      setKIOSKHOLDERdataRetreived(Holders);
    } else {
      setKIOSKHOLDERdataRetreived([]);
    }

    let StockTakeSelectID = localStorage.getItem("StocktakeID");

    // Now update the Data to include the Holder id in the sql selection

    if (StockTakeSelectID && CounterSelectID) {
      let { data: DbdataScanns } = await httpService.get(
        config.apiItemScannedCounterGet +
          "?stockTakeID=" +
          StockTakeSelectID +
          "&" +
          "counterID=" +
          CounterSelectID
      );

      if (DbdataScanns.length != 0) {
        setStockItemScanned(DbdataScanns);
      } else {
        setStockItemScanned([]);
      }
    } //close if stock take and holder selected
  };

  const handleNotes = async (StockTakeSelectID) => {
    //Get the Stock take notes
    let { data: DbdataNotes } = await httpService.get(
      config.apiNotesGet + "?stocktakeID=" + StockTakeSelectID
    );

    setStockTakeNotes(DbdataNotes);
  };

  const handleHolderSelection = async () => {
    // reset the variables
    setholderID(null);
    localStorage.setItem("HolderID", "");
    // Get the selected kiosk
    var e = document.getElementById("HolderSelect");

    //Get the value of the selected option
    let HolderSelectID = e.value;
    setholderID(HolderSelectID);
    localStorage.setItem("HolderID", HolderSelectID);

    // holderID
    let StockTakeSelectID = localStorage.getItem("StocktakeID");
    let CounterSelectID = localStorage.getItem("CounterID");

    if (StockTakeSelectID && CounterSelectID && HolderSelectID) {
      let { data: DbdataScanns } = await httpService.get(
        config.apiItemScannedCounterHolderGet +
          "?stockTakeID=" +
          StockTakeSelectID +
          "&" +
          "counterID=" +
          CounterSelectID +
          "&" +
          "holderID=" +
          HolderSelectID
      );
      if (DbdataScanns.length != 0) {
        setStockItemScanned(DbdataScanns);
      } else {
        setStockItemScanned([]);
      }
    }
  };

  const handleSelect = async () => {
    // Get the Value of the select the user has chosen
    var e = document.getElementById("StockTakeSelect");

    //Get the value of the selected option
    let StockTakeSelectID = e.value;

    // Get the text of the selected option
    var selectText = e.options[e.selectedIndex].text;
    setStockTakeID(StockTakeSelectID);
    localStorage.setItem("StocktakeID", StockTakeSelectID);

    if (selectText == "Select Stock Take...") {
      localStorage.setItem("kioskID", "");
      localStorage.setItem("CounterID", "");
      localStorage.setItem("HolderID", "");

      // reset the counter and Holder
      setKIOSKCOUNTERdataRetreived([]);
      setKIOSKHOLDERdataRetreived([]);

      // reset the variables
      setkioskID(null);
      setcounterID(null);
      setholderID(null);

      setDisabled(true);
      setgoToPage("pageItemScan");
    } else {
      setDisabled(false);
      setgoToPage("PageItemSearchTable");
      // Get the already scanned items

      handleGetScannedItems(StockTakeSelectID);

      // Get the Kiosk id and Load the counters
      let { data: stocktakeSingle } = await httpService.get(
        config.apiStockTakeGet + "?id=" + StockTakeSelectID
      );

      handleKioskSelection(stocktakeSingle.kioskID, StockTakeSelectID);
      handleNotes(StockTakeSelectID);
    }
  };

  const handleSort = (sortColumn) => {
    setsortColumn(sortColumn);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleSearchDesc = (query) => {
    setSearchQueryDesc(query);
  };

  const renderTooltip = (message, ...props) => (
    <Tooltip id="button-tooltip" className="tooltip-km" {...props}>
      {message}
    </Tooltip>
  );

  // filter the data from search input contents (Scans already made)
  let filtered = stockItemsScanned;
  if (searchQuery)
    filtered = stockItemsScanned.filter((m) =>
      m.itemCode.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  if (searchQueryDesc)
    filtered = stockItemsScanned.filter((m) =>
      m.description.toLowerCase().includes(searchQueryDesc.toLowerCase())
    );

  // filter the data from search input contents (Scans already made)
  let filteredCTC = stockItemsToCount;
  if (searchQuery)
    filteredCTC = stockItemsToCount.filter((m) =>
      m.itemCode.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  if (searchQueryDesc)
    filteredCTC = stockItemsToCount.filter((m) =>
      m.description.toLowerCase().includes(searchQueryDesc.toLowerCase())
    );

  return (
    <div>
      <div id="overlay" className="overlay">
        <div id="text" className="text">
          <PuffLoader
            color={color}
            loading={loading}
            cssOverride={cssOverride}
            size={50}
          />
          Uploading Data...
        </div>
      </div>
      <ToastContainer
        draggable={true}
        transition={Zoom}
        autoClose={200}
        position="top-center"
      />
      <div className="row">
        <div>
          <form>
            <div className="align-buttons">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Select a stock take to count")}
              >
                <select
                  className="form-select button-size-400 button-margin-all"
                  aria-label="Default select example"
                  id="StockTakeSelect"
                  onChange={handleSelect}
                >
                  <option defaultValue>Select Stock Take...</option>
                  {STdataRetreived.map((STdataRetreivedSingle) => (
                    <option
                      key={STdataRetreivedSingle.id}
                      value={STdataRetreivedSingle.id}
                    >
                      {`${STdataRetreivedSingle["stocktakeName"]} (${STdataRetreivedSingle["stockTakeDate"]})`}
                    </option>
                  ))}
                </select>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Select a counter the item is in")}
              >
                <select
                  className="form-select button-size-400 button-margin-all"
                  aria-label="Default select example"
                  id="CounterSelect"
                  onChange={handleCounterSelection}
                >
                  <option defaultValue>Select the Counter...</option>
                  {KIOSKCOUNTERdataRetreived.map(
                    (KIOSKCOUNTERdataRetreivedSingle) => (
                      <option
                        key={KIOSKCOUNTERdataRetreivedSingle.id}
                        value={KIOSKCOUNTERdataRetreivedSingle.id}
                      >
                        {KIOSKCOUNTERdataRetreivedSingle["counterName"]}
                      </option>
                    )
                  )}
                </select>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Select a holder the item is in")}
              >
                <select
                  className="form-select button-size-400 button-margin-all"
                  aria-label="Default select example"
                  id="HolderSelect"
                  onChange={handleHolderSelection}
                >
                  <option defaultValue>Select the holder...</option>
                  {KIOSKHOLDERdataRetreived.map(
                    (KIOSKHOLDERdataRetreivedSingle) => (
                      <option
                        key={KIOSKHOLDERdataRetreivedSingle.id}
                        value={KIOSKHOLDERdataRetreivedSingle.id}
                      >
                        {KIOSKHOLDERdataRetreivedSingle["holder_name"]}
                      </option>
                    )
                  )}
                </select>
              </OverlayTrigger>
              <BarcodeReader
                onError={handleError}
                onScan={handleScan}
                disabled={disabledVar}
              />
            </div>
          </form>
        </div>
        <div className="row">
          <div className="align-buttons align-center-all ">
            <div className="col" data-aos="fade-right">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Add an item not on the list")}
              >
                <Link
                  to={`/${goToPage}`}
                  className="btn button-color-babyblue button-margin-all button-size-200 "
                >
                  Not on S/take
                </Link>
              </OverlayTrigger>
            </div>

            <SearchBox
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search Item code ..."
            />

            <SearchBox
              value={searchQueryDesc}
              onChange={handleSearchDesc}
              placeholder="Search Description ..."
            />
          </div>
        </div>
        <div>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active tab-color"
                id="nav-CTC-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-CTC"
                type="button"
                role="tab"
                aria-controls="nav-CTC"
                aria-selected="true"
              >
                Click to count
              </button>
              <button
                className="nav-link  tab-color"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Counted
              </button>
              <button
                className="nav-link tab-color"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Count Summary
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-CTC"
              role="tabpanel"
              aria-labelledby="nav-CTC-tab"
            >
              <div className="anyClass overflow-auto padding-all-4 ">
                <Table
                  columns={columnsCTC}
                  sortColumn={sortColumn}
                  onSort={handleSort}
                  data={filteredCTC}
                />
              </div>
              <div className=" row button-margin-all">
                <div className="col ">Total Items Scanned</div>

                <div className="col align-end-all m-0 p-0">
                  <input
                    className="form-control  textfeild-size-100 align-text-center button-margin-all"
                    type="text"
                    placeholder="0"
                    aria-label="default input example"
                    value={stockItemsScanned.length}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade show "
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="anyClass overflow-auto padding-all-4 ">
                <Table
                  columns={columns}
                  sortColumn={sortColumn}
                  onSort={handleSort}
                  data={filtered}
                />
              </div>

              <div className=" row button-margin-all">
                <div className="col ">Total Counted</div>

                <div className="col align-end-all m-0 p-0">
                  {filtered && (
                    <Excelexport
                      excelData={filtered}
                      fileName={"Counted Items"}
                      tabname={"Counted"}
                    />
                  )}
                  <input
                    className="form-control  textfeild-size-100 align-text-center button-margin-all"
                    type="text"
                    placeholder="0"
                    aria-label="default input example"
                    value={stockItemsScanned.length}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="anyClass overflow-auto padding-all-4 ">
                <Table
                  columns={columnsStats}
                  sortColumn={sortColumn}
                  onSort={handleSort}
                  data={totalsArray}
                />
              </div>
              <div className=" row button-margin-all">
                <div className="col ">Total Counted</div>

                <div className="col align-end-all m-0 p-0">
                  {totalsArray && (
                    <Excelexport
                      excelData={totalsArray}
                      fileName={"Counted Totals"}
                      tabname={"Totals"}
                    />
                  )}
                  <input
                    className="form-control  textfeild-size-100 align-text-center button-margin-all"
                    type="text"
                    placeholder="0"
                    aria-label="default input example"
                    value={stockItemsScanned.length}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemScan;
