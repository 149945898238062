import React, { Component } from "react";

import Input from "./input";
import Select from "./select";
import TextArea from "./textArea";
import Checkbox from "./checkbox";

// Services
import httpService from "./../services/httpService.js";
import config from "../../config.json";

// import Joi from "joi";
import Joi from "joi-browser";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    buttonClicked: "",
  };

  setSaveButtonVar() {
    this.buttonClicked = "Save";
  }

  setDeleteButtonVar() {
    this.buttonClicked = "Delete";
  }

  setClearButtonVar() {
    this.buttonClicked = "Clear";
  }

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    const actionType = this.buttonClicked;
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    // find out which button was pressed. Then choose the correct function

    if (actionType === "Save") {
      this.doSubmit();
    }

    if (actionType === "Delete") {
      this.doDelete();
    }

    if (actionType === "Clear") {
      this.doClear();
    }
  };

  handleholder = async (counterID) => {
    let { data: Holders } = await httpService.get(
      config.apiCounterHoldersGet + "?id=" + counterID
    );

    if (Holders.length == 0) {
      this.setState({
        holders: [],
      });
    }

    this.setState({
      holders: Holders,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    // create a new array of errors
    const errors = { ...this.state.errors };

    // Validate the errors
    const errorMessage = this.validateProperty(input);

    // If no erorrs get the value and update state data
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });

    // load a select with information
    if (input.name == "counterID") {
      if (input.value == "Select...") {
        console.log("on select...");
      } else {
        this.handleholder(input.value);
      }
    }
  };

  renderSaveButton(label) {
    return (
      <button
        className="btn btn-primary"
        disabled={this.validate()}
        id="save"
        name="save"
        onClick={() => this.setSaveButtonVar()}
      >
        {label}
      </button>
    );
  }

  renderButtonDelete(label) {
    return (
      <button
        className="btn btn-danger"
        disabled={this.validate()}
        id="delete"
        name="delete"
        onClick={() => this.setDeleteButtonVar()}
      >
        {label}
      </button>
    );
  }

  renderButtonClear(label) {
    return (
      <button
        className="btn btn-warning"
        id="clear"
        name="clear"
        onClick={() => this.setClearButtonVar()}
      >
        {label}
      </button>
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        error={errors[name]}
        label={label}
        name={name}
        onChange={this.handleChange}
        options={options}
        value={data[name]}
      />
    );
  }

  renderInput(name, label, disabled, type = "text") {
    const { data, errors } = this.state;

    return (
      <Input
        disabled={disabled}
        error={errors[name]}
        label={label}
        name={name}
        onChange={this.handleChange}
        type={type}
        value={data[name]}
      />
    );
  }

  renderUpload(name, label, type = "file") {
    const { data, errors } = this.state;

    return (
      <Input
        error={errors[name]}
        // label={label}
        name={name}
        onChange={this.handleChange}
        type={type}
        value={data[name]}
      />
    );
  }

  renderPassword(name, label, type = "password") {
    const { data, errors } = this.state;

    return (
      <Input
        error={errors[name]}
        label={label}
        name={name}
        onChange={this.handleChange}
        type={type}
        value={data[name]}
      />
    );
  }

  renderTextArea(name, label, type, cols, rows) {
    const { data, errors } = this.state;

    return (
      <TextArea
        cols={cols}
        error={errors[name]}
        label={label}
        name={name}
        onChange={this.handleChange}
        rows={rows}
        type={type}
        value={data[name]}
      />
    );
  }

  renderCheckbox(name, label, type) {
    const { data, errors } = this.state;

    return (
      <Checkbox
        error={errors[name]}
        label={label}
        name={name}
        onChange={this.handleChange}
        type={type}
        value={data[name]}
      />
    );
  }
}

export default Form;
